import { loadStripe } from '@stripe/stripe-js';
import { db } from './firebase';
import { collection, addDoc, onSnapshot, getDocs, query, where, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

// Initialize Stripe with publishable key
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

export { stripePromise };

export const createCheckoutSession = async (userId) => {
  try {
    console.log('Creating checkout session for user:', userId);
    
    // Create the checkout session
    const checkoutRef = doc(collection(db, 'customers', userId, 'checkout_sessions'));
    
    console.log('Created checkout session ref');
    
    // Set the checkout session data
    await setDoc(checkoutRef, {
      price: 'price_1QTZRgJzyRFFYTFUMMF1GQdq', // Your price ID
      success_url: window.location.origin + '/account?success=true',
      cancel_url: window.location.origin + '/account?canceled=true',
      mode: 'subscription',
    });
    
    console.log('Added checkout session doc');

    // Wait for the redirect URL from the extension
    const unsubscribe = onSnapshot(checkoutRef, async (snap) => {
      const { sessionId } = snap.data();
      console.log('Got sessionId:', sessionId);
      
      if (sessionId) {
        // Initialize Stripe
        const stripe = await stripePromise;
        
        // Redirect to checkout
        const { error } = await stripe.redirectToCheckout({ sessionId });
        
        if (error) {
          console.error('Stripe redirect error:', error);
          throw error;
        }
      }
    });

    return unsubscribe;
  } catch (error) {
    console.error('Error in createCheckoutSession:', error);
    throw error;
  }
};

export const cancelSubscription = async (userId, subscriptionId) => {
  try {
    console.log('Canceling subscription:', subscriptionId, 'for user:', userId);
    
    // Get user data
    const userDoc = await getDoc(doc(db, 'users', userId));
    const userData = userDoc.data();
    
    // Update subscription
    const subscriptionRef = doc(db, 'customers', userId, 'subscriptions', subscriptionId);
    await updateDoc(subscriptionRef, {
      cancel_at_period_end: true,
      metadata: {
        canceledAt: new Date().toISOString()
      }
    });

    // Send webhook to Make.com
    await fetch('https://hook.us1.make.com/drvajjict941cwgzvr734f4rslab4fqu', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId,
        subscriptionId,
        userEmail: userData.email,
        canceledAt: new Date().toISOString(),
        stripeCustomerId: userData.stripeCustomerId,
        subscriptionStatus: 'cancellation_requested'
      })
    });

    console.log('Sent cancellation webhook to Make.com');
    return { success: true };
  } catch (error) {
    console.error('Error in cancelSubscription:', error);
    throw error;
  }
};