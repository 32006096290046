import { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../services/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { 
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut
} from 'firebase/auth';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log('Auth user:', user);
        // Get additional user data from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        console.log('User doc data:', userDoc.data());
        
        const customerDoc = await getDoc(doc(db, 'customers', user.uid));
        console.log('Customer doc data:', customerDoc.data());
        
        const userData = {
          ...user,
          ...userDoc.data(),
          subscriptionStatus: customerDoc?.data()?.subscriptionStatus || 'free'
        };
        console.log('Final user data:', userData);
        setUser(userData);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const register = async (email, password, name) => {
    try {
      // First check if user exists
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      
      console.log('Created auth user:', user.uid);

      // Create user profile in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        name,
        email,
        createdAt: new Date().toISOString(),
        subscriptionStatus: 'free'
      });
      console.log('Created users collection document');

      // Create customer profile for Stripe
      await setDoc(doc(db, 'customers', user.uid), {
        email,
        stripeId: null,  // Will be set when they first interact with Stripe
        subscriptionStatus: 'free'
      });
      console.log('Created customers collection document');

      return { success: true };
    } catch (error) {
      console.error('Registration error:', error);
      
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('An account with this email already exists. Please login instead.');
      } else {
        throw new Error('Failed to create account. Please try again.');
      }
    }
  };

  const login = async (email, password) => {
    try {
      // First sign in with Firebase Auth
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      
      // Wait for Firestore data to be loaded
      const [userDoc, customerDoc] = await Promise.all([
        getDoc(doc(db, 'users', user.uid)),
        getDoc(doc(db, 'customers', user.uid))
      ]);

      // Set the user data immediately
      const userData = {
        ...user,
        ...userDoc.data(),
        subscriptionStatus: customerDoc?.data()?.subscriptionStatus || 'free'
      };
      setUser(userData);

      return userData;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      register,
      login,
      logout: () => signOut(auth)
    }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext); 