import React, { useState } from 'react';
import GameStats from './GameStats';
import PlayerStats from './PlayerStats';
import NFLPredictions from './sports/NFL/NFLPredictions';
import NBAPredictions from './sports/NBA/NBAPredictions';
import NBAPlayerStats from './sports/NBA/NBAPlayerStats';
import { useSport } from '../../contexts/SportContext';

const GameOverview = ({ game, predictions, onClose }) => {
  const [activeTab, setActiveTab] = useState('Team Stats');
  const [activeTeam, setActiveTeam] = useState('home');
  const { teams } = game;
  const { activeSport } = useSport();

  console.log('GameOverview - Raw game data:', game);
  console.log('GameOverview - Teams data:', teams);

  const handleTeamSelect = (team) => {
    console.log('Team selected:', team, {
      teamId: teams[team].id,
      gameId: game.id
    });
    setActiveTeam(team);
  };

  const renderPredictions = () => {
    if (activeSport === 'NBA') {
      return <NBAPredictions predictions={predictions} />;
    }
    return <NFLPredictions predictions={predictions} />;
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-[#1a1f2e] rounded-lg w-full max-w-3xl shadow-xl flex flex-col max-h-[90vh]">
        {/* Game Header */}
        <div className="p-4 flex flex-col items-center bg-[#141824] rounded-t-lg flex-shrink-0">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center space-x-4">
              <img src={teams.home.logo} alt={teams.home.name} className="w-10 h-10" />
              <div>
                <div className="text-white">{teams.home.name}</div>
                <div className="text-gray-400 text-sm">{teams.home.code}</div>
              </div>
            </div>

            <div className="text-white text-lg">VS</div>

            <div className="flex items-center space-x-4">
              <div className="text-right">
                <div className="text-white">{teams.away.name}</div>
                <div className="text-gray-400 text-sm">{teams.away.code}</div>
              </div>
              <img src={teams.away.logo} alt={teams.away.name} className="w-10 h-10" />
            </div>
          </div>
        </div>

        {/* Overview Header - Always visible */}
        <div className="flex items-center justify-between p-3 border-b border-gray-700 flex-shrink-0">
          <h3 className="text-lg font-medium text-white">
            Game Overview
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white"
          >
            ✕
          </button>
        </div>

        {/* Navigation Tabs - Always visible */}
        <div className="flex space-x-4 p-4 flex-shrink-0">
          {['Team Stats', 'Players', 'Predictions'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-4 py-2 rounded ${
                activeTab === tab
                  ? 'bg-blue-500 text-white'
                  : 'text-gray-400 hover:bg-gray-700/50'
              }`}
            >
              {tab === 'Team Stats' && '📊'}
              {tab === 'Players' && '👥'}
              {tab === 'Predictions' && '📍'}
              <span className="ml-2">{tab}</span>
            </button>
          ))}
        </div>

        {/* Scrollable Content Area */}
        <div className="flex-1 overflow-y-auto min-h-0">
          <div className="p-4">
            {/* Team Toggle */}
            {(activeTab === 'Team Stats' || activeTab === 'Players') && (
              <div className="flex rounded-lg overflow-hidden border border-gray-700 mb-4">
                <button
                  onClick={() => handleTeamSelect('home')}
                  className={`flex-1 py-2 px-4 ${
                    activeTeam === 'home' ? 'bg-blue-500' : 'bg-gray-800/50'
                  }`}
                >
                  <img 
                    src={teams.home.logo} 
                    alt={teams.home.name} 
                    className="w-6 h-6"
                  />
                  <span className="text-sm font-medium">{teams.home.name}</span>
                </button>
                <button
                  onClick={() => handleTeamSelect('away')}
                  className={`flex-1 py-2 px-4 ${
                    activeTeam === 'away' ? 'bg-blue-500' : 'bg-gray-800/50'
                  }`}
                >
                  <img 
                    src={teams.away.logo} 
                    alt={teams.away.name} 
                    className="w-6 h-6"
                  />
                  <span className="text-sm font-medium">{teams.away.name}</span>
                </button>
              </div>
            )}

            {/* Tab Content */}
            {activeTab === 'Team Stats' && (
              <GameStats 
                teamId={activeTeam === 'home' ? teams.home.id : teams.away.id}
                sport={activeSport}
                gameId={game.id}
              />
            )}
            
            {activeTab === 'Players' && (
              activeSport === 'NBA' ? (
                <NBAPlayerStats 
                  teamId={activeTeam === 'home' ? teams.home.id : teams.away.id}
                />
              ) : (
                <PlayerStats 
                  gameId={game.id}
                  teamId={activeTeam === 'home' ? teams.home.id : teams.away.id}
                  sport={activeSport}
                />
              )
            )}

            {activeTab === 'Predictions' && renderPredictions()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameOverview;