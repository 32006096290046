import { useNBATeamStats } from './sports/useNBATeamStats';
import { useNFLTeamStats } from './sports/useNFLTeamStats';
import { useSport } from '../contexts/SportContext';

export const useTeamStats = (teamId) => {
  const { activeSport } = useSport();
  
  const nbaStats = useNBATeamStats(teamId);
  const nflStats = useNFLTeamStats(teamId);

  return activeSport === 'NBA' ? nbaStats : nflStats;
}; 