import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';

export const useNBAPlayerStats = (teamId) => {
  const [playerStats, setPlayerStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      if (!teamId) {
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching NBA player stats for teamId:', teamId);

        // 1. Get completed games for this team
        const gamesRef = collection(db, 'nba_games');
        const gamesSnapshot = await getDocs(query(gamesRef));
        
        const completedGames = gamesSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(game => 
            (game.status?.short === 'FT' || game.status?.long === 'Game Finished') &&
            (game.teams.home.id === Number(teamId) || game.teams.away.id === Number(teamId))
          );

        console.log('Found completed games:', completedGames.length);

        // 2. For each completed game, get the team's players
        const statsPromises = completedGames.map(async game => {
          // Get players for this team in this game
          const playersRef = collection(db, 'nba_players');
          const playersQuery = query(
            playersRef,
            where('teamId', '==', Number(teamId))
          );
          const playersSnapshot = await getDocs(playersQuery);
          const players = playersSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          console.log(`Found ${players.length} players for game ${game.id}`);

          // 3. Get statistics for each player in this game
          const playerStatsRef = collection(db, 'nba_playerStatistics');
          const playerStatsPromises = players.map(async player => {
            const statsQuery = query(
              playerStatsRef,
              where('gameId', '==', Number(game.id)),
              where('playerId', '==', Number(player.id))
            );
            
            const statsSnapshot = await getDocs(statsQuery);
            if (!statsSnapshot.empty) {
              return {
                ...player,
                ...statsSnapshot.docs[0].data()
              };
            }
            return null;
          });

          const playerStats = (await Promise.all(playerStatsPromises))
            .filter(Boolean); // Remove null entries

          return {
            gameId: game.id,
            gameDate: game.date,
            opponent: game.teams.home.id === Number(teamId) 
              ? game.teams.away 
              : game.teams.home,
            score: {
              team: game.teams.home.id === Number(teamId) 
                ? game.scores.home.total 
                : game.scores.away.total,
              opponent: game.teams.home.id === Number(teamId)
                ? game.scores.away.total
                : game.scores.home.total
            },
            players: playerStats
          };
        });

        const statsResults = await Promise.all(statsPromises);
        console.log('Final processed stats:', statsResults);
        setPlayerStats(statsResults);
      } catch (err) {
        console.error('Error fetching NBA player stats:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [teamId]);

  return { playerStats, loading, error };
}; 