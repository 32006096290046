import React, { useState } from 'react';

const NBAGameStatItem = ({ stat }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!stat) return null;

  return (
    <div className="bg-gray-800/50 rounded-lg overflow-hidden">
      {/* Header - Always visible */}
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-4 py-3 flex items-center hover:bg-gray-700/50"
      >
        <div className="flex items-center w-48">
          <img 
            src={stat.opponent.logo} 
            alt={stat.opponent.name}
            className="w-6 h-6 mr-2"
          />
          <span className="text-white">vs {stat.opponent.name}</span>
        </div>
        
        <div className="flex-1 flex items-center">
          <span className="text-gray-300 mr-8">
            {stat.points?.total || 0} - {stat.score.opponent}
          </span>
          <span className="text-gray-500">
            {new Date(stat.gameDate).toLocaleDateString('en-US', {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric'
            })}
          </span>
        </div>

        <div className="text-blue-400 ml-4">
          {isExpanded ? '▼' : '▶'}
        </div>
      </button>

      {/* Collapsible Content */}
      {isExpanded && (
        <div className="p-3 space-y-4 border-t border-gray-700/50">
          {/* Scoring */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">Scoring</h4>
            <div className="grid grid-cols-2 gap-2">
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Points</div>
                <div className="text-sm text-white">{stat.points?.total || 0}</div>
              </div>
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Quarter Breakdown</div>
                <div className="text-sm text-white">
                  1st: {stat.points?.quarter_1 || 0} | 2nd: {stat.points?.quarter_2 || 0} | 3rd: {stat.points?.quarter_3 || 0} | 4th: {stat.points?.quarter_4 || 0}
                  {stat.points?.over_time && ` | OT: ${stat.points.over_time}`}
                </div>
              </div>
            </div>
          </div>

          {/* Shooting Details */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">Shooting Details</h4>
            <div className="grid grid-cols-3 gap-2">
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">FG</div>
                <div className="text-sm text-white">{stat.fieldGoals?.total || 0}/{stat.fieldGoals?.attempts || 0}</div>
              </div>
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">3PT</div>
                <div className="text-sm text-white">{stat.threePoints?.total || 0}/{stat.threePoints?.attempts || 0}</div>
              </div>
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">FT</div>
                <div className="text-sm text-white">{stat.freeThrows?.total || 0}/{stat.freeThrows?.attempts || 0}</div>
              </div>
            </div>
          </div>

          {/* Percentages */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">Shooting %</h4>
            <div className="grid grid-cols-3 gap-2">
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">FG%</div>
                <div className="text-sm text-white">{stat.fieldGoals?.percentage || 0}%</div>
              </div>
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">3P%</div>
                <div className="text-sm text-white">{stat.threePoints?.percentage || 0}%</div>
              </div>
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">FT%</div>
                <div className="text-sm text-white">{stat.freeThrows?.percentage || 0}%</div>
              </div>
            </div>
          </div>

          {/* Rebounds */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">Rebounds</h4>
            <div className="grid grid-cols-3 gap-2">
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Offensive</div>
                <div className="text-sm text-white">{stat.rebounds?.offensive || 0}</div>
              </div>
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Defensive</div>
                <div className="text-sm text-white">{stat.rebounds?.defensive || 0}</div>
              </div>
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Total</div>
                <div className="text-sm text-white">{stat.rebounds?.total || 0}</div>
              </div>
            </div>
          </div>

          {/* Additional Stats */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">Additional Stats</h4>
            <div className="grid grid-cols-3 gap-2">
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Assists</div>
                <div className="text-sm text-white">{stat.assists || 0}</div>
              </div>
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Steals</div>
                <div className="text-sm text-white">{stat.steals || 0}</div>
              </div>
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Blocks</div>
                <div className="text-sm text-white">{stat.blocks || 0}</div>
              </div>
            </div>
          </div>

          {/* Other Stats */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">Other</h4>
            <div className="grid grid-cols-2 gap-2">
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Turnovers</div>
                <div className="text-sm text-white">{stat.turnovers || 0}</div>
              </div>
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Personal Fouls</div>
                <div className="text-sm text-white">{stat.personalFouls || 0}</div>
              </div>
            </div>
          </div>

          {stat.type === 'season_average' && (
            <div className="mt-2 text-xs text-gray-400">
              Games Played: {stat.gamesPlayed || 0}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NBAGameStatItem; 