import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDcOIvC8jH7WE3rMfgXhLHvbzo4a41LpUM",
  authDomain: "strategixbets.firebaseapp.com",
  projectId: "strategixbets",
  storageBucket: "strategixbets.firebasestorage.app",
  messagingSenderId: "881836401879",
  appId: "1:881836401879:web:a00fb64d6c319ff6a9fc92"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);