export const calculatePlayerSeasonAverages = (playerStats) => {
  if (!playerStats || Object.keys(playerStats).length === 0) return null;

  const allGames = Object.values(playerStats);
  const allPlayers = {};

  // Collect all unique players and their stats across games
  allGames.forEach(game => {
    Object.values(game.players).forEach(playerData => {
      const playerId = playerData.player.id;
      
      if (!allPlayers[playerId]) {
        allPlayers[playerId] = {
          player: playerData.player,
          team: playerData.team,
          games: 0,
          statGroups: {}
        };
      }

      allPlayers[playerId].games++;

      // Aggregate stats for each group
      Object.entries(playerData.statGroups).forEach(([group, stats]) => {
        if (!allPlayers[playerId].statGroups[group]) {
          allPlayers[playerId].statGroups[group] = {};
        }

        stats.forEach(stat => {
          if (!allPlayers[playerId].statGroups[group][stat.name]) {
            allPlayers[playerId].statGroups[group][stat.name] = 0;
          }
          allPlayers[playerId].statGroups[group][stat.name] += Number(stat.value) || 0;
        });
      });
    });
  });

  // Calculate averages
  Object.values(allPlayers).forEach(player => {
    Object.values(player.statGroups).forEach(group => {
      Object.keys(group).forEach(statName => {
        group[statName] = (group[statName] / player.games).toFixed(1);
      });
    });
  });

  return allPlayers;
}; 