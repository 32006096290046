import React from 'react';
import NFLPlayerGameItem from './sports/NFL/NFLPlayerGameItem';
import NBAPlayerGameItem from './sports/NBA/NBAPlayerGameItem';

const PlayerGameItem = ({ gameId, players, sport }) => {
  console.log('PlayerGameItem - gameId:', gameId);
  console.log('PlayerGameItem - players:', players);
  console.log('PlayerGameItem - sport:', sport);

  if (!gameId || !players) return null;

  // Default to NFL if sport is not specified
  const gameSport = sport || 'NFL';

  if (gameSport === 'NFL') {
    return <NFLPlayerGameItem gameId={gameId} players={players} />;
  }

  // Default to NBA
  return <NBAPlayerGameItem gameId={gameId} players={players} />;
};

export default PlayerGameItem; 