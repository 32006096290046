import React from 'react';
import InfoTooltip from '../../../common/InfoTooltip';

const NFLGameCardPredictions = ({ predictions }) => {
  const getTotalPoints = (predictions) => {
    if (!predictions?.teamTotalPoints?.home?.predictedPoints || 
        !predictions?.teamTotalPoints?.away?.predictedPoints) {
      return '--';
    }
    return predictions.teamTotalPoints.home.predictedPoints + 
           predictions.teamTotalPoints.away.predictedPoints;
  };

  return (
    <div className="flex flex-col gap-2 p-3">
      {/* Game Winner */}
      <div className="bg-gray-800/50 p-2 rounded flex justify-between items-start">
        <div>
          <div className="flex items-center space-x-2">
            <div className="text-xs text-gray-400">Projected Winner</div>
            <InfoTooltip content={predictions?.gameWinner?.reasoning} />
          </div>
          <div className="text-sm font-medium truncate max-w-[160px]">
            {predictions?.gameWinner?.winner || 'Analyzing...'}
          </div>
        </div>
        <div className="text-sm text-blue-400">
          {predictions?.gameWinner?.confidence ? 
            `${Math.round(predictions.gameWinner.confidence * (predictions.gameWinner.confidence < 1 ? 100 : 1))}%` 
            : '--'}
        </div>
      </div>
      
      {/* Total Points */}
      <div className="bg-gray-800/50 p-2 rounded flex justify-between items-start">
        <div>
          <div className="flex items-center space-x-2">
            <div className="text-xs text-gray-400">Total Points</div>
            <InfoTooltip 
              content={predictions?.teamTotalPoints ? 
                `${predictions.teamTotalPoints.home.team}: ${predictions.teamTotalPoints.home.reasoning}\n\n${predictions.teamTotalPoints.away.team}: ${predictions.teamTotalPoints.away.reasoning}`
                : null
              }
            />
          </div>
          <div className="text-sm font-medium">
            {getTotalPoints(predictions) !== '--' ? `${getTotalPoints(predictions)} points` : '--'}
          </div>
        </div>
        <div className="text-sm text-blue-400">
          {predictions?.teamTotalPoints?.home?.confidence && predictions?.teamTotalPoints?.away?.confidence ? 
            `${Math.min(100, Math.round(((predictions.teamTotalPoints.home.confidence + predictions.teamTotalPoints.away.confidence) / 2) * 100))}%` 
            : '--'}
        </div>
      </div>
      
      {/* First TD Scorer */}
      <div className="bg-gray-800/50 p-2 rounded">
        <div className="flex items-center space-x-2 mb-1">
          <div className="text-xs text-gray-400">First TD Scorer</div>
          <InfoTooltip 
            content={predictions?.firstTouchdown?.predictions?.map(p => 
              `${p.player} (${p.team}): ${p.reasoning}`
            ).join('\n\n')}
          />
        </div>
        {predictions?.firstTouchdown?.predictions ? (
          predictions.firstTouchdown.predictions.slice(0, 3).map((p) => (
            <div key={p.player} className="flex justify-between items-center">
              <div className="text-sm font-medium">
                {p.player}
              </div>
              <div className="text-sm text-blue-400">
                {Math.round(p.confidence * (p.confidence < 1 ? 100 : 1))}%
              </div>
            </div>
          ))
        ) : (
          <div className="text-sm font-medium">Analyzing...</div>
        )}
      </div>
    </div>
  );
};

export default NFLGameCardPredictions; 