import React, { useState } from 'react';

const PlayerSeasonAverageItem = ({ averages, selectedGroup }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [expandedPlayers, setExpandedPlayers] = useState({});

  if (!averages) return null;

  const togglePlayer = (playerId) => {
    setExpandedPlayers(prev => ({
      ...prev,
      [playerId]: !prev[playerId]
    }));
  };

  return (
    <div className="bg-gray-700/30 rounded-lg overflow-hidden mb-4 border border-blue-500/30">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-between p-3 hover:bg-gray-600/30"
      >
        <div className="flex items-center space-x-3">
          <span className="text-sm font-medium text-blue-400">Season Averages</span>
        </div>
        <span className="text-gray-400 transform transition-transform duration-200">
          {isExpanded ? '▼' : '▶'}
        </span>
      </button>

      {isExpanded && (
        <div className="p-3 space-y-2">
          {Object.values(averages)
            .filter(player => 
              selectedGroup === 'all' || 
              player.statGroups[selectedGroup]
            )
            .map(player => (
              <div key={player.player.id} className="bg-gray-800/50 rounded overflow-hidden">
                <button
                  onClick={() => togglePlayer(player.player.id)}
                  className="w-full flex items-center justify-between p-3 hover:bg-gray-700/50"
                >
                  <div className="flex items-center space-x-2">
                    <img src={player.player.image} alt="" className="w-8 h-8 rounded-full" />
                    <div>
                      <div className="text-white text-sm font-medium">{player.player.name}</div>
                      <div className="text-gray-400 text-xs">{player.games} games played</div>
                    </div>
                  </div>
                  <span className="text-gray-400 transform transition-transform duration-200">
                    {expandedPlayers[player.player.id] ? '▼' : '▶'}
                  </span>
                </button>

                {expandedPlayers[player.player.id] && (
                  <div className="p-3 space-y-3 border-t border-gray-700/50">
                    {Object.entries(player.statGroups)
                      .filter(([group]) => selectedGroup === 'all' || group === selectedGroup)
                      .map(([group, stats]) => (
                        <div key={group}>
                          <h4 className="text-xs text-gray-400 uppercase mb-2">{group}</h4>
                          <div className="bg-gray-800/50 p-2 rounded">
                            <div className="grid grid-cols-2 gap-2">
                              {Object.entries(stats).map(([statName, value]) => (
                                <div key={statName}>
                                  <div className="text-xs text-gray-400">{statName.replace(/_/g, ' ')}</div>
                                  <div className="text-sm text-white">{value}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default PlayerSeasonAverageItem; 