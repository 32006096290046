import { useSport } from '../contexts/SportContext';

export const calculateSeasonAverages = (stats) => {
  if (!stats || stats.length === 0) return null;

  const totals = stats.reduce((acc, game) => {
    const { statistics } = game;
    
    const [comp, att] = (statistics.passing?.comp_att || '0-0').split('-').map(Number);
    acc.passing.completions = (acc.passing.completions || 0) + (comp || 0);
    acc.passing.attempts = (acc.passing.attempts || 0) + (att || 0);
    
    return {
      yards: {
        total: (acc.yards?.total || 0) + (statistics.yards?.total || 0),
      },
      points_against: {
        total: (acc.points_against?.total || 0) + (statistics.points_against?.total || 0),
      },
      passing: {
        ...acc.passing,
        total: (acc.passing?.total || 0) + (statistics.passing?.total || 0),
      },
      rushings: {
        attempts: (acc.rushings?.attempts || 0) + (statistics.rushings?.attempts || 0),
        total: (acc.rushings?.total || 0) + (statistics.rushings?.total || 0),
      },
      first_downs: {
        total: (acc.first_downs?.total || 0) + (statistics.first_downs?.total || 0),
      }
    };
  }, { passing: {} });

  const gamesCount = stats.length;
  const completionPercentage = totals.passing.attempts > 0 
    ? ((totals.passing.completions / totals.passing.attempts) * 100).toFixed(1)
    : '0.0';

  return {
    statistics: {
      yards: {
        total: Math.round(totals.yards.total / gamesCount),
      },
      points_against: {
        total: Math.round(totals.points_against.total / gamesCount),
      },
      passing: {
        comp_att: `${completionPercentage}%`,
        total: Math.round(totals.passing.total / gamesCount),
      },
      rushings: {
        attempts: Math.round(totals.rushings.attempts / gamesCount),
        total: Math.round(totals.rushings.total / gamesCount),
      },
      first_downs: {
        total: Math.round(totals.first_downs.total / gamesCount),
      }
    }
  };
};

export const calculateAverages = (totals, games, sport) => {
  if (!totals || games === 0) return null;

  if (sport === 'NBA') {
    return totals; // NBA stats are already averaged
  }

  // NFL averages calculation
  return {
    passing: {
      attempts: (totals.passing.attempts / games).toFixed(1),
      completions: (totals.passing.completions / games).toFixed(1),
      yards: (totals.passing.yards / games).toFixed(1),
      touchdowns: (totals.passing.touchdowns / games).toFixed(1),
      interceptions: (totals.passing.interceptions / games).toFixed(1)
    },
    rushing: {
      attempts: (totals.rushing.attempts / games).toFixed(1),
      yards: (totals.rushing.yards / games).toFixed(1),
      touchdowns: (totals.rushing.touchdowns / games).toFixed(1)
    },
    receiving: {
      receptions: (totals.receiving.receptions / games).toFixed(1),
      yards: (totals.receiving.yards / games).toFixed(1),
      touchdowns: (totals.receiving.touchdowns / games).toFixed(1)
    },
    defense: {
      sacks: (totals.defense.sacks / games).toFixed(1),
      interceptions: (totals.defense.interceptions / games).toFixed(1),
      fumblesRecovered: (totals.defense.fumblesRecovered / games).toFixed(1)
    },
    scoring: {
      points: (totals.scoring.points / games).toFixed(1),
      pointsAllowed: (totals.scoring.pointsAllowed / games).toFixed(1)
    }
  };
}; 