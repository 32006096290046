import React from 'react';
import { useTeamStats } from '../../hooks/useTeamStats';
import GameStatItem from './GameStatItem';
import SeasonAverageItem from './SeasonAverageItem';
import { calculateSeasonAverages } from '../../utils/statsAverages';
import NBAGameStats from './sports/NBA/NBAGameStats';

const GameStats = ({ teamId, sport, gameId }) => {
  if (sport === 'NBA') {
    return <NBAGameStats teamId={teamId} gameId={gameId} />;
  }

  // Existing NFL logic remains unchanged
  const { teamStats, loading, error } = useTeamStats(teamId);
  const seasonAverages = calculateSeasonAverages(teamStats, sport);

  if (loading) {
    return <div className="text-gray-400 text-center py-4">Loading stats...</div>;
  }

  if (error) {
    return <div className="text-red-400 text-center py-4">Error loading stats: {error}</div>;
  }

  if (!teamStats || teamStats.length === 0) {
    return <div className="text-gray-400 text-center py-4">No stats available</div>;
  }

  return (
    <div className="space-y-2 max-h-[400px] overflow-y-auto pr-2">
      <SeasonAverageItem averages={seasonAverages} sport={sport} />
      {teamStats.map((stat) => (
        <GameStatItem key={stat.id} stat={stat} sport={sport} />
      ))}
    </div>
  );
};

export default GameStats; 