import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';

export const useNFLPlayerStats = (teamId) => {
  const [playerStats, setPlayerStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlayerStats = async () => {
      if (!teamId) {
        setLoading(false);
        return;
      }

      try {
        const statsRef = collection(db, 'gamePlayerStatistics');
        const statsSnapshot = await getDocs(statsRef);
        
        // Group all player stats by gameId
        const statsData = statsSnapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
          if (data.teamId === Number(teamId)) {
            const gameId = data.gameId;
            if (!acc[gameId]) {
              acc[gameId] = {
                gameId,
                players: {}
              };
            }
            
            const playerId = data.player.id;
            if (!acc[gameId].players[playerId]) {
              acc[gameId].players[playerId] = {
                player: data.player,
                team: data.team,
                statGroups: {}
              };
            }
            
            acc[gameId].players[playerId].statGroups[data.group] = data.statistics;
          }
          return acc;
        }, {});

        // Debug logs
        console.log(`Found stats for ${Object.keys(statsData).length} games`);
        console.log('Stats data structure:', statsData);
        
        setPlayerStats(statsData);
      } catch (err) {
        console.error('Error fetching player stats:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerStats();
  }, [teamId]);

  return { playerStats, loading, error };
}; 