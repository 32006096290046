import React, { useState } from 'react';

const SeasonAverageItem = ({ averages }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  if (!averages || !averages.statistics) return null;

  const {
    statistics: {
      yards = {},
      points_against = {},
      passing = {},
      rushings = {},
      first_downs = {},
    }
  } = averages;

  return (
    <div className="bg-gray-700/30 rounded-lg overflow-hidden mb-4 border border-blue-500/30">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-between p-3 hover:bg-gray-600/30"
      >
        <div className="flex items-center space-x-3">
          <span className="text-sm font-medium text-blue-400">Season Averages</span>
        </div>
        <span className="text-gray-400 transform transition-transform duration-200">
          {isExpanded ? '▼' : '▶'}
        </span>
      </button>

      {isExpanded && (
        <div className="p-3 space-y-4">
          {/* Offensive Stats */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">OFFENSE</h4>
            <div className="grid grid-cols-2 gap-2">
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Total Yards</div>
                <div className="text-sm text-white">{yards?.total || 0}</div>
              </div>
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Points</div>
                <div className="text-sm text-white">{points_against?.total || 0}</div>
              </div>
            </div>
          </div>

          {/* Passing Stats */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">PASSING</h4>
            <div className="bg-gray-800/50 p-2 rounded">
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <div className="text-xs text-gray-400">Comp/Att</div>
                  <div className="text-sm text-white">{passing?.comp_att || '0/0'}</div>
                </div>
                <div>
                  <div className="text-xs text-gray-400">Yards</div>
                  <div className="text-sm text-white">{passing?.total || 0}</div>
                </div>
              </div>
            </div>
          </div>

          {/* Rushing Stats */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">RUSHING</h4>
            <div className="bg-gray-800/50 p-2 rounded">
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <div className="text-xs text-gray-400">Attempts</div>
                  <div className="text-sm text-white">{rushings?.attempts || 0}</div>
                </div>
                <div>
                  <div className="text-xs text-gray-400">Yards</div>
                  <div className="text-sm text-white">{rushings?.total || 0}</div>
                </div>
              </div>
            </div>
          </div>

          {/* Additional Stats */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">ADDITIONAL STATS</h4>
            <div className="grid grid-cols-2 gap-2">
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">First Downs</div>
                <div className="text-sm text-white">{first_downs?.total || 0}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SeasonAverageItem; 