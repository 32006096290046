import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useSport } from '../../contexts/SportContext';

const Navbar = () => {
  const [showNBATooltip, setShowNBATooltip] = useState(false);
  const [showNHLTooltip, setShowNHLTooltip] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { activeSport, setActiveSport } = useSport();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <nav className="bg-[#0B1120] border-b border-gray-800">
      <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/" className="flex items-center">
              <span className="text-xl font-semibold tracking-tight">
                <span className="text-gray-100">Strategix</span>
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600 font-bold">Picks</span>
              </span>
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="border-2 border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white px-3 py-1 rounded-md text-sm font-semibold transition-all duration-200"
            >
              {isMobileMenuOpen ? 'Close' : 'Select Sport'}
            </button>
          </div>

          {/* Center Navigation */}
          <div className="hidden md:flex items-center space-x-4">
            <button 
              onClick={() => setActiveSport('NFL')}
              className={`${
                activeSport === 'NFL' 
                  ? 'bg-blue-600 text-white' 
                  : 'text-gray-300 hover:text-white'
              } px-4 py-2 rounded-md text-sm font-medium flex items-center`}
            >
              <img src="/images/nfl-logo.svg" alt="NFL" className="w-5 h-5 mr-2" />
              NFL
            </button>
            <button 
              onClick={() => setActiveSport('NBA')}
              className={`${
                activeSport === 'NBA' 
                  ? 'bg-blue-600 text-white' 
                  : 'text-gray-300 hover:text-white'
              } px-4 py-2 rounded-md text-sm font-medium flex items-center`}
            >
              <img src="/images/nba-logo.svg" alt="NBA" className="w-5 h-5 mr-2" />
              NBA
            </button>
            <div className="relative">
              <button 
                className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-center"
                onMouseEnter={() => setShowNHLTooltip(true)}
                onMouseLeave={() => setShowNHLTooltip(false)}
              >
                <img 
                  src="/images/nhl-logo.svg" 
                  alt="NHL" 
                  className="w-5 h-5 mr-2"
                />
                NHL
              </button>
              {showNHLTooltip && (
                <div className="absolute -bottom-12 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs py-2 px-3 rounded shadow-lg whitespace-nowrap">
                  Coming Soon
                </div>
              )}
            </div>
          </div>

          {/* Account */}
          <div className="flex items-center space-x-4">
            {user ? (
              <div className="relative group">
                <button className="flex items-center space-x-3 px-4 py-2 rounded-xl bg-gradient-to-r from-blue-900/30 to-blue-800/30 hover:from-blue-800/40 hover:to-blue-700/40 border border-white/5 transition-all duration-200">
                  <div className="relative">
                    <div className="w-8 h-8 rounded-lg bg-gradient-to-br from-blue-500 to-blue-600 flex items-center justify-center text-white font-medium shadow-lg">
                      {user.name ? user.name[0].toUpperCase() : '?'}
                    </div>
                    <div className="absolute -bottom-0.5 -right-0.5 w-2.5 h-2.5 bg-green-500 rounded-full border-2 border-[#0B1120]"></div>
                  </div>
                  <span className="text-gray-200 font-medium">{user.name || 'Account'}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
                
                {/* Modern Dropdown Menu */}
                <div className="absolute right-0 w-64 mt-2 pt-2 pb-1 bg-gradient-to-b from-gray-900 to-gray-800 rounded-xl border border-white/10 shadow-xl backdrop-blur-sm opacity-0 translate-y-2 invisible group-hover:opacity-100 group-hover:translate-y-0 group-hover:visible transition-all duration-200 z-50">
                  <div className="px-4 py-2 border-b border-gray-700/50">
                    <p className="text-sm text-gray-400">Signed in as</p>
                    <p className="text-sm font-medium text-white">{user.email}</p>
                  </div>
                  
                  <div className="px-2 py-1.5">
                    <Link
                      to="/"
                      className="flex items-center space-x-3 px-3 py-2 rounded-lg text-gray-300 hover:text-white hover:bg-white/5 transition-colors duration-150"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                      </svg>
                      <span className="text-sm font-medium">Dashboard</span>
                    </Link>
                    
                    <Link
                      to="/account"
                      className="flex items-center space-x-3 px-3 py-2 rounded-lg text-gray-300 hover:text-white hover:bg-white/5 transition-colors duration-150"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg>
                      <span className="text-sm font-medium">Profile Settings</span>
                    </Link>
                    
                    <div className="px-2 py-1.5 mt-1 border-t border-gray-700/50">
                      <button
                        onClick={handleLogout}
                        className="flex items-center space-x-3 px-3 py-2 w-full rounded-lg text-red-400 hover:text-red-300 hover:bg-red-500/10 transition-colors duration-150"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        <span className="text-sm font-medium">Sign Out</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <Link
                  to="/login"
                  className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="bg-blue-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-600"
                >
                  Register
                </Link>
              </div>
            )}
          </div>
        </div>

        {/* Mobile menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden py-2">
            <div className="flex flex-col space-y-2 px-2">
              <button 
                onClick={() => {
                  setActiveSport('NFL');
                  setIsMobileMenuOpen(false);
                }}
                className={`${
                  activeSport === 'NFL' 
                    ? 'bg-blue-600 text-white' 
                    : 'text-gray-300 hover:text-white'
                } px-4 py-2 rounded-md text-sm font-medium flex items-center`}
              >
                <img src="/images/nfl-logo.svg" alt="NFL" className="w-5 h-5 mr-2" />
                NFL
              </button>
              <button 
                onClick={() => {
                  setActiveSport('NBA');
                  setIsMobileMenuOpen(false);
                }}
                className={`${
                  activeSport === 'NBA' 
                    ? 'bg-blue-600 text-white' 
                    : 'text-gray-300 hover:text-white'
                } px-4 py-2 rounded-md text-sm font-medium flex items-center`}
              >
                <img src="/images/nba-logo.svg" alt="NBA" className="w-5 h-5 mr-2" />
                NBA
              </button>
              <div className="relative">
                <button 
                  className="text-gray-300 hover:text-white px-4 py-2 rounded-md text-sm font-medium flex items-center w-full"
                  onMouseEnter={() => setShowNHLTooltip(true)}
                  onMouseLeave={() => setShowNHLTooltip(false)}
                >
                  <img src="/images/nhl-logo.svg" alt="NHL" className="w-5 h-5 mr-2" />
                  NHL
                </button>
                {showNHLTooltip && (
                  <div className="absolute -right-16 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs py-2 px-3 rounded shadow-lg whitespace-nowrap">
                    Coming Soon
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;