import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';

export const useNFLTeamStats = (teamId) => {
  const [teamStats, setTeamStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTeamStats = async () => {
      if (!teamId) {
        setLoading(false);
        return;
      }

      try {
        // First get all team stats
        const statsRef = collection(db, 'teamStatistics');
        const statsQuery = query(
          statsRef,
          where('team.id', '==', Number(teamId))
        );
        
        const statsSnapshot = await getDocs(statsQuery);
        const statsData = statsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Then get corresponding games to check stages
        const gamesRef = collection(db, 'games');
        const gameIds = statsData.map(stat => stat.gameId);
        
        // Batch get games in chunks of 10 (Firestore limit)
        const gameChunks = [];
        for (let i = 0; i < gameIds.length; i += 10) {
          const chunk = gameIds.slice(i, i + 10);
          const gamesQuery = query(
            gamesRef,
            where('id', 'in', chunk)
          );
          gameChunks.push(getDocs(gamesQuery));
        }

        const gameSnapshots = await Promise.all(gameChunks);
        const games = gameSnapshots.flatMap(snapshot => 
          snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
        );

        console.log('Games data:', games);

        // Join stats with game info and filter out preseason
        const statsWithStage = statsData.map(stat => {
          const game = games.find(g => g.id === stat.gameId);
          return {
            ...stat,
            game
          };
        });

        // Sort by gameId (newest first)
        statsWithStage.sort((a, b) => b.gameId - a.gameId);

        setTeamStats(statsWithStage);
      } catch (err) {
        console.error('Error fetching team stats:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamStats();
  }, [teamId]);

  return { teamStats, loading, error };
}; 