import React from 'react';
import NBAGameStatItem from './sports/NBA/NBAGameStatItem';
import NFLGameStatItem from './sports/NFL/NFLGameStatItem';

const GameStatItem = ({ stat, sport }) => {
  if (!stat) return null;

  if (sport === 'NFL') {
    return <NFLGameStatItem stats={stat} />;
  }

  return <NBAGameStatItem stat={stat} />;
};

export default GameStatItem;