import React from 'react';
import { useNBATeamStats } from '../../../../hooks/sports/useNBATeamStats';
import NBAGameStatItem from './NBAGameStatItem';

const NBAGameStats = ({ teamId }) => {
  const { teamStats, loading, error } = useNBATeamStats(teamId);

  if (loading) {
    return <div className="text-gray-400 text-center py-4">Loading stats...</div>;
  }

  if (error) {
    return <div className="text-red-400 text-center py-4">Error loading stats: {error}</div>;
  }

  if (!teamStats || teamStats.length === 0) {
    return <div className="text-gray-400 text-center py-4">No stats available</div>;
  }

  // Sort stats by date (most recent first)
  const sortedStats = [...teamStats].sort((a, b) => 
    new Date(b.gameDate) - new Date(a.gameDate)
  );

  return (
    <div className="space-y-2 max-h-[400px] overflow-y-auto pr-2">
      {sortedStats.map((stat) => (
        <NBAGameStatItem key={stat.id} stat={stat} />
      ))}
    </div>
  );
};

export default NBAGameStats; 