import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../services/firebase';
import { cancelSubscription } from '../services/stripe';
import SubscribeButton from '../components/subscription/SubscribeButton';

// Helper function to format timestamps
const formatDate = (timestamp) => {
  if (!timestamp) return null;
  
  // Handle Firestore Timestamp objects
  const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp * 1000);
  
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  }).format(date);
};

const Account = () => {
  const { user } = useAuth();
  const [subscription, setSubscription] = useState(null);
  const [cancelling, setCancelling] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!user) return;

    const subscriptionRef = collection(db, 'customers', user.uid, 'subscriptions');
    
    const unsubscribe = onSnapshot(subscriptionRef, (snapshot) => {
      console.log('Subscription docs:', snapshot.docs.map(doc => ({id: doc.id, ...doc.data()})));
      
      const activeSub = snapshot.docs.find(doc => {
        const data = doc.data();
        return data.status === 'active';
      });

      console.log('Active subscription found:', activeSub ? 'yes' : 'no');
      
      if (activeSub) {
        const subData = activeSub.data();
        setSubscription({
          id: activeSub.id,
          ...subData,
          formattedDates: {
            created: formatDate(subData.created),
            currentPeriodEnd: formatDate(subData.current_period_end),
            currentPeriodStart: formatDate(subData.current_period_start)
          },
          isCanceling: subData.cancel_at_period_end
        });
      } else {
        setSubscription(null);
      }
    });

    return () => unsubscribe();
  }, [user]);

  const handleCancelSubscription = async () => {
    if (!window.confirm('Are you sure you want to cancel your subscription?')) {
      return;
    }

    setCancelling(true);
    setError('');

    try {
      await cancelSubscription(user.uid, subscription.id);
      // The subscription status will be updated automatically through the Firebase listener
    } catch (err) {
      console.error('Error canceling subscription:', err);
      setError('Failed to cancel subscription. Please try again.');
    } finally {
      setCancelling(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto space-y-8">
        {/* Header Section */}
        <div className="text-center">
          <h1 className="text-3xl font-bold text-white">Account Settings</h1>
          <p className="mt-2 text-gray-400">Manage your profile and subscription</p>
        </div>

        {/* Profile Card */}
        <div className="bg-gray-800/50 backdrop-blur-sm rounded-2xl border border-gray-700/50 overflow-hidden">
          <div className="p-6">
            <div className="flex items-center space-x-4">
              <div className="w-16 h-16 rounded-xl bg-gradient-to-br from-blue-500 to-blue-600 flex items-center justify-center text-2xl font-bold text-white">
                {user?.email[0].toUpperCase()}
              </div>
              <div>
                <h2 className="text-xl font-semibold text-white">Profile Details</h2>
                <p className="text-gray-400 mt-1">{user?.email}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Subscription Card */}
        <div className="bg-gray-800/50 backdrop-blur-sm rounded-2xl border border-gray-700/50 overflow-hidden">
          <div className="p-6">
            <h2 className="text-xl font-semibold text-white mb-6">Subscription Status</h2>
            
            {error && (
              <div className="mb-6 p-4 rounded-lg bg-red-500/10 border border-red-500/50 text-red-400">
                <div className="flex items-center space-x-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                  <span>{error}</span>
                </div>
              </div>
            )}
            
            {subscription ? (
              <div className="space-y-6">
                {/* Subscription Status Banner */}
                <div className={`p-4 rounded-lg ${
                  subscription.isCanceling 
                    ? 'bg-yellow-500/10 border border-yellow-500/50' 
                    : 'bg-green-500/10 border border-green-500/50'
                }`}>
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <div className={`font-medium ${
                        subscription.isCanceling ? 'text-yellow-400' : 'text-green-400'
                      }`}>
                        {subscription.isCanceling ? 'Subscription (Canceling)' : 'Active Subscription'}
                      </div>
                      <div className="text-sm text-gray-400">
                        Started: {subscription.formattedDates.created}
                      </div>
                    </div>
                    {!subscription.isCanceling && (
                      <button
                        onClick={handleCancelSubscription}
                        disabled={cancelling}
                        className={`
                          px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                          ${cancelling 
                            ? 'bg-gray-700 text-gray-400 cursor-not-allowed' 
                            : 'bg-red-500/10 text-red-400 hover:bg-red-500/20 hover:text-red-300'}
                        `}
                      >
                        {cancelling ? 'Cancelling...' : 'Cancel Subscription'}
                      </button>
                    )}
                  </div>
                </div>

                {/* Next Billing Info */}
                <div className="flex items-center space-x-2 text-sm text-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                  </svg>
                  <span>
                    {subscription.isCanceling 
                      ? `Access until: ${subscription.formattedDates.currentPeriodEnd}`
                      : `Next billing date: ${subscription.formattedDates.currentPeriodEnd}`
                    }
                  </span>
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                <div className="text-gray-400">
                  No active subscription found. Subscribe now to access premium features!
                </div>
                <SubscribeButton />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;