import React from 'react';
import { useGames } from '../hooks/useGames';
import GameCard from '../components/games/GameCard';

const GamesTest = () => {
  const { games, loading, error } = useGames();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[calc(100vh-4rem)]">
        <div className="relative">
          {/* Cyberpunk-inspired loading animation */}
          <div className="absolute inset-0 rounded-xl border-2 border-blue-500/20 animate-pulse"></div>
          <div className="w-24 h-24 rounded-xl border-l-2 border-t-2 border-blue-500 animate-spin"></div>
          <div className="absolute inset-[8px] rounded-xl border-r-2 border-b-2 border-blue-400/40 animate-spin" style={{ animationDuration: '1.5s' }}></div>
          <div className="absolute inset-[16px] rounded-xl border-l-2 border-t-2 border-purple-500/40 animate-spin" style={{ animationDuration: '2s' }}></div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#0B1120]">
      {/* Announcement Banner */}
      <div className="bg-gradient-to-r from-blue-600/10 via-purple-600/10 to-blue-600/10 border-b border-white/10">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-3">
          <div className="flex items-center justify-center text-sm">
            <div className="flex items-center space-x-2">
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-500/10 text-blue-400">
                NEW
              </span>
              <p className="text-gray-300">
                <span className="font-semibold">NBA Now Available!</span>
                {" "}Enjoy our upgraded user experience. Our AI is constantly learning and improving.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Tech-inspired background pattern */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_500px_at_50%_200px,rgba(120,119,198,0.3),transparent)] pointer-events-none"></div>
      <div className="absolute inset-0 bg-[url('/grid-pattern.svg')] opacity-[0.02] pointer-events-none"></div>
      
      <div className="relative max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {games.map(game => (
            <div 
              key={game.id}
              className="group relative transform perspective-1000"
            >
              {/* Ambient glow effect */}
              <div className="absolute -inset-[1px] bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 rounded-xl blur-lg group-hover:blur-xl transition-all duration-300 opacity-0 group-hover:opacity-100"></div>
              
              {/* Hover lift effect */}
              <div className="transform-gpu group-hover:-translate-y-1 transition-transform duration-300 ease-out">
                <GameCard game={game} />
              </div>
              
              {/* Bottom reflection */}
              <div className="absolute inset-x-0 h-40 bottom-0 bg-gradient-to-t from-blue-500/[0.03] to-transparent rounded-b-xl pointer-events-none"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GamesTest;