import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

const InfoTooltip = ({ content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0, placement: 'bottom' });
  const buttonRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target) && 
          buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const updatePosition = () => {
    if (!buttonRef.current || !tooltipRef.current) return;

    const buttonRect = buttonRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    
    // Check if there's room below
    const spaceBelow = windowHeight - buttonRect.bottom;
    const spaceAbove = buttonRect.top;
    const needsFlip = spaceBelow < tooltipRect.height && spaceAbove > spaceBelow;

    const left = buttonRect.right + 10;
    const top = needsFlip 
      ? buttonRect.top - tooltipRect.height + 20
      : buttonRect.top - 10;

    setPosition({
      top,
      left,
      placement: needsFlip ? 'top' : 'bottom'
    });
  };

  useEffect(() => {
    if (isVisible) {
      updatePosition();
      // Update position on scroll or resize
      window.addEventListener('scroll', updatePosition, true);
      window.addEventListener('resize', updatePosition);
      
      return () => {
        window.removeEventListener('scroll', updatePosition, true);
        window.removeEventListener('resize', updatePosition);
      };
    }
  }, [isVisible]);

  if (!content) return null;

  return (
    <>
      <button
        ref={buttonRef}
        onClick={() => setIsVisible(!isVisible)}
        className="text-gray-400 hover:text-white transition-colors"
      >
        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </button>
      
      {isVisible && createPortal(
        <div 
          ref={tooltipRef}
          style={{
            position: 'fixed',
            top: `${position.top}px`,
            left: `${position.left}px`,
          }}
          className="z-50 w-80 p-4 bg-gray-800 rounded-lg shadow-lg border border-gray-700"
        >
          <div className="max-h-[300px] overflow-y-auto custom-scrollbar pr-2">
            <div className="text-sm text-gray-300 whitespace-pre-line leading-relaxed">
              {content}
            </div>
          </div>
          <div 
            className={`absolute w-2 h-2 -left-1 transform -translate-x-1/2 rotate-45 bg-gray-800 border-l border-t border-gray-700
              ${position.placement === 'top' ? 'bottom-4' : 'top-4'}`}
          />
        </div>,
        document.body
      )}
    </>
  );
};

export default InfoTooltip; 