import React from 'react';
import InfoTooltip from '../../../common/InfoTooltip';

const NBAPredictions = ({ predictions }) => {
  return (
    <div className="space-y-4">
      {/* Main Game Predictions */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        {/* Game Winner */}
        <div className="bg-gray-800/50 p-3 rounded">
          <div className="flex items-center space-x-2">
            <div className="text-xs text-gray-400">Game Winner</div>
            <InfoTooltip content={predictions?.gameWinner?.reasoning} />
          </div>
          <div className="flex justify-between items-center mt-1">
            <div className="text-sm font-medium text-white">
              {predictions?.gameWinner?.winner || 'Analyzing...'}
            </div>
            <div className="text-sm text-blue-400">
              {predictions?.gameWinner?.confidence}%
            </div>
          </div>
        </div>

        {/* Total Points */}
        <div className="bg-gray-800/50 p-3 rounded">
          <div className="flex items-center space-x-2">
            <div className="text-xs text-gray-400">Total Points</div>
            <InfoTooltip content={predictions?.totalPoints?.reasoning} />
          </div>
          <div className="flex justify-between items-center mt-1">
            <div className="text-sm font-medium text-white">
              {predictions?.totalPoints?.total ? 
                `${predictions.totalPoints.total}`
                : 'Analyzing...'}
            </div>
            <div className="text-sm text-blue-400">
              {predictions?.totalPoints?.confidence}%
            </div>
          </div>
        </div>
      </div>

      {/* Player Performance Predictions */}
      <div className="mt-6">
        <h4 className="text-sm font-medium text-gray-400 mb-3">Player Performance Predictions</h4>
        <div className="space-y-3">
          {predictions?.playerPerformance?.playerPerformance?.predictions?.map((player, index) => (
            <div key={index} className="bg-gray-800/50 p-3 rounded">
              <div className="flex items-center justify-between mb-2">
                <div className="text-white font-medium">{player.playerName}</div>
                <div className="text-blue-400">{player.confidence}%</div>
              </div>
              
              <div className="grid grid-cols-3 gap-4 mb-2">
                <div className="text-sm">
                  <span className="text-gray-400">Points: </span>
                  <span className="text-white font-medium">{player.points}</span>
                </div>
                <div className="text-sm">
                  <span className="text-gray-400">Rebounds: </span>
                  <span className="text-white font-medium">{player.rebounds}</span>
                </div>
                <div className="text-sm">
                  <span className="text-gray-400">Assists: </span>
                  <span className="text-white font-medium">{player.assists}</span>
                </div>
              </div>
              
              <div className="text-sm text-gray-400">
                {player.reasoning}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NBAPredictions; 