import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../services/firebase';

export const useGameDetails = (gameId) => {
  const [gameDetails, setGameDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGameDetails = async () => {
      if (!gameId) {
        setLoading(false);
        return;
      }

      try {
        const gamesRef = collection(db, 'games');
        const q = query(gamesRef, where('id', '==', Number(gameId)));
        const gameSnapshot = await getDocs(q);
        
        if (!gameSnapshot.empty) {
          const gameData = {
            id: gameSnapshot.docs[0].id,
            ...gameSnapshot.docs[0].data()
          };
          setGameDetails(gameData);
        }
      } catch (err) {
        console.error('Error fetching game details:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGameDetails();
  }, [gameId]);

  return { gameDetails, loading, error };
}; 