import React, { useState, useRef, useEffect } from 'react';
import OpenAI from 'openai';
import { firestoreFunctions } from './firestoreFunctions';
import { marked } from 'marked';
import NFLChat from './NFLChat';
import NBAChat from './NBAChat';
import { useSport } from '../../contexts/SportContext';

const functionDefinitions = [
  {
    name: "getPredictionData",
    description: "Get detailed prediction data for a specific NFL game including team stats, key players, and matchup context",
    parameters: {
      type: "object",
      properties: {
        gameId: {
          type: "string",
          description: "The game ID to get prediction data for"
        }
      },
      required: ["gameId"]
    },
    function: {
      name: "getPredictionData",
      description: "Get detailed prediction data for a specific NFL game including team stats, key players, and matchup context",
      parameters: {
        type: "object",
        properties: {
          gameId: {
            type: "string",
            description: "The game ID to get prediction data for"
          }
        },
        required: ["gameId"]
      }
    }
  }
];

// Add these conversation starters
const conversationStarters = {
  NFL: {
    gameAnalysis: [
      "What are the key matchup advantages for either team?",
      "Can you analyze the offensive strategies for both teams?",
      "What are the defensive strengths and weaknesses of each team?",
      "How do the quarterbacks compare statistically?",
      "What's the injury status for key players in this game?",
    ],
    statistics: [
      "Show me the season statistics for both teams",
      "Compare the rushing yards for both teams",
      "What are the passing statistics for both quarterbacks?",
      "How do these teams rank in their divisions?",
      "What are the turnover statistics for both teams?",
    ],
    predictions: [
      "Based on recent performance, which team has the advantage?",
      "What are the key factors that could decide this game?",
      "How have these teams performed against each other historically?",
      "Which team has the stronger offense?",
      "Which team has the better defense?",
    ]
  },
  NBA: {
    gameAnalysis: [
      "What are the key matchups to watch in this game?",
      "How do these teams' playing styles compare?",
      "What are the strengths and weaknesses of each team?",
      "How important is home court advantage in this matchup?",
      "What's the injury report for this game?",
    ],
    statistics: [
      "Compare the teams' shooting percentages",
      "How do their benches compare statistically?",
      "What are the rebounding numbers for both teams?",
      "Compare their defensive ratings",
      "How do they match up in points per game?",
    ],
    predictions: [
      "Which team has momentum coming into this game?",
      "What are the key factors for tonight's game?",
      "How have recent matchups between these teams gone?",
      "Which team has the scoring advantage?",
      "Who has the defensive edge?",
    ]
  }
};

// Move SuggestionBubbles to its own component file
const SuggestionBubbles = ({ onSelect, sport }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const sportStarters = conversationStarters[sport];
  
  if (!isExpanded) {
    return (
      <div className="flex items-center gap-2 text-sm">
        <span className="text-gray-300 font-medium">Conversation Starters</span>
        <button
          onClick={() => setIsExpanded(true)}
          className="text-blue-400 hover:text-blue-300 flex items-center gap-1"
        >
          <span>Click here for great questions to ask</span>
          <svg 
            className="w-4 h-4" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M19 9l-7 7-7-7" 
            />
          </svg>
        </button>
      </div>
    );
  }

  return (
    <div className="mb-4 space-y-3">
      <div className="flex items-center justify-between">
        <span className="text-gray-300 text-sm font-medium">Conversation Starters</span>
        <button
          onClick={() => setIsExpanded(false)}
          className="text-gray-400 hover:text-white"
        >
          <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      
      <div className="grid grid-cols-1 gap-2">
        {Object.entries(sportStarters).map(([category, questions]) => (
          <div key={category} className="space-y-2">
            <div 
              className="text-gray-300 font-medium cursor-pointer hover:bg-gray-800 rounded px-2 py-1"
              onClick={() => setSelectedCategory(selectedCategory === category ? null : category)}
            >
              {category.replace(/([A-Z])/g, ' $1').trim()}
              <span className="float-right">{selectedCategory === category ? '▼' : '▶'}</span>
            </div>
            
            {selectedCategory === category && (
              <div className="pl-2 space-y-1">
                {questions.map((question, index) => (
                  <div
                    key={index}
                    className="text-sm text-gray-400 hover:text-white hover:bg-gray-800 rounded px-2 py-1 cursor-pointer"
                    onClick={() => {
                      onSelect(question);
                      setIsExpanded(false);
                    }}
                  >
                    {question}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const ChatWindow = ({ game, onClose }) => {
  const { activeSport } = useSport();
  
  console.log('Game data received:', game);

  // Ensure we have both game and sport information
  if (!game || !activeSport) {
    console.error('Missing required props:', { game, activeSport });
    return (
      <div className="text-red-500">
        Unable to load chat: Missing game or sport information
      </div>
    );
  }

  // Pass shared components and data to sport-specific chats
  const sharedProps = {
    game,
    onClose,
    SuggestionBubbles,
    conversationStarters: conversationStarters[activeSport]
  };

  // Determine which sport-specific chat component to render
  switch (activeSport) {
    case 'NBA':
      return <NBAChat {...sharedProps} />;
    case 'NFL':
      return <NFLChat {...sharedProps} />;
    default:
      console.error('Unsupported sport:', activeSport);
      return (
        <div className="text-red-500">
          Unsupported sport type: {activeSport}
        </div>
      );
  }
};

export default ChatWindow;