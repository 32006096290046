import React, { useState, useRef, useEffect } from 'react';
import OpenAI from 'openai';
import { firestoreFunctions } from './firestoreFunctions';
import { marked } from 'marked';
import InfoTooltip from '../common/InfoTooltip';

const NFLChat = ({ game, onClose, SuggestionBubbles, conversationStarters }) => {
  const { teams } = game;
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([
    { 
      text: `Hi! I can help you analyze the ${game.teams.away.name} vs ${game.teams.home.name} game. What would you like to know?`, 
      isAi: true 
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const messagesEndRef = useRef(null);

  const openai = new OpenAI({
    apiKey: import.meta.env.VITE_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
  });

  console.log('NFL Chat received game:', game);

  // Initialize thread with NFL game data
  useEffect(() => {
    const initializeThread = async () => {
      try {
        const thread = await openai.beta.threads.create();
        setThreadId(thread.id);

        // Get NFL prediction data
        const predictionData = await firestoreFunctions.getPredictionData({
          gameId: game.id.toString(),
          sport: 'NFL'
        });

        if (!predictionData.success) {
          console.error('Prediction data error:', predictionData.error);
          throw new Error('Failed to fetch prediction data');
        }

        // Send initial NFL context
        await openai.beta.threads.messages.create(
          thread.id,
          {
            role: "user",
            content: `NFL Game Context: ${game.teams.away.name} vs ${game.teams.home.name} (Game ID: ${game.id}). 
                     Please provide concise, direct answers about NFL-specific analysis using only the prediction data provided.
                     Only elaborate when specifically asked for more details.
                     Prediction Data: ${JSON.stringify(predictionData.data)}`
          }
        );
      } catch (error) {
        console.error('Error initializing thread:', error);
        setMessages(prev => [...prev, { 
          text: "I apologize, but I'm having trouble accessing the NFL game data. Please try again.", 
          isAi: true 
        }]);
      }
    };

    initializeThread();
  }, [game]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || !threadId) return;

    const userMessage = inputMessage.trim();
    setInputMessage('');
    setMessages(prev => [...prev, { text: userMessage, isAi: false }]);
    setIsLoading(true);

    try {
      // Add the user's message to the thread
      await openai.beta.threads.messages.create(
        threadId,
        {
          role: "user",
          content: userMessage
        }
      );

      // Create a run with the NFL assistant
      const run = await openai.beta.threads.runs.create(
        threadId,
        {
          assistant_id: "asst_Ju8n9vW9A55aMPK7VdWujmf2", // NFL-specific assistant
          instructions: "You are an NFL analysis expert. Provide concise, accurate responses about NFL games."
        }
      );

      // Poll for the response
      let response;
      while (true) {
        const runStatus = await openai.beta.threads.runs.retrieve(
          threadId,
          run.id
        );

        if (runStatus.status === 'completed') {
          const messages = await openai.beta.threads.messages.list(
            threadId
          );
          response = messages.data[0].content[0].text.value;
          break;
        } else if (runStatus.status === 'failed') {
          throw new Error('Run failed');
        }

        await new Promise(resolve => setTimeout(resolve, 500));
      }

      setMessages(prev => [...prev, { text: response, isAi: true }]);
    } catch (error) {
      console.error('Error in NFL chat:', error);
      setMessages(prev => [...prev, { 
        text: "I apologize, but I encountered an error processing your NFL-related request.", 
        isAi: true 
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleClearChat = () => {
    setMessages([
      { text: 'How can I help analyze this NFL game?', isAi: true }
    ]);
  };

  const formatAiMessage = (message) => {
    const renderedMessage = marked(message);
    return (
      <div
        className="prose prose-invert"
        dangerouslySetInnerHTML={{ __html: renderedMessage }}
      ></div>
    );
  };

  const handleSuggestionSelect = (suggestion) => {
    setInputMessage(suggestion);
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-[#1a1f2e] rounded-lg w-full max-w-lg shadow-xl max-h-[90vh] flex flex-col">
        {/* Game Header */}
        <div className="p-4 flex flex-col items-center bg-[#141824] rounded-t-lg shrink-0 relative">
          {/* Beta badge */}
          <div className="absolute top-2 right-2 flex items-center gap-2">
            <span className="text-xs text-gray-400">NFL AI Assistant in Beta</span>
            <span className="flex h-2 w-2">
              <span className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-blue-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-500"></span>
            </span>
          </div>

          {/* Teams */}
          <div className="flex items-center justify-center gap-4 mb-2">
            <div className="flex flex-col items-center">
              <img 
                src={teams.away.logo} 
                alt={teams.away.name} 
                className="w-10 h-10 object-contain" 
              />
              <div className="text-white mt-1 text-sm">{teams.away.name}</div>
            </div>
            <div className="text-gray-400">vs</div>
            <div className="flex flex-col items-center">
              <img 
                src={teams.home.logo} 
                alt={teams.home.name} 
                className="w-10 h-10 object-contain" 
              />
              <div className="text-white mt-1 text-sm">{teams.home.name}</div>
            </div>
          </div>
        </div>

        {/* Chat Header */}
        <div className="flex items-center justify-between p-3 border-b border-gray-700 shrink-0">
          <h3 className="text-lg font-medium text-white">NFL Game Analysis</h3>
          <div className="flex gap-2">
            <button
              onClick={handleClearChat}
              className="text-gray-400 hover:text-white text-sm px-2 py-1 rounded bg-gray-800/50"
            >
              Clear Chat
            </button>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white"
            >
              ✕
            </button>
          </div>
        </div>

        {/* Chat Content */}
        <div className="flex-1 overflow-y-auto p-4">
          {messages.map((message, index) => (
            <div 
              key={index} 
              className={`mb-6 ${message.isAi ? '' : 'text-blue-400 text-right'}`}
            >
              {message.isAi ? formatAiMessage(message.text) : message.text}
            </div>
          ))}
          {isLoading && (
            <div className="text-gray-400 text-center">
              Thinking...
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area */}
        <div className="p-4 border-t border-gray-700 shrink-0">
          <div className="mb-4">
            {messages.length === 1 && (
              <SuggestionBubbles onSelect={handleSuggestionSelect} sport={game.sport} />
            )}
          </div>
          
          <form onSubmit={handleSubmit} className="flex gap-2">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Ask about this NFL game..."
              className="flex-1 bg-[#141824] rounded px-4 py-3 text-gray-300 focus:outline-none"
            />
            <button
              type="submit"
              disabled={!inputMessage.trim()}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Send
            </button>
          </form>

          {/* Beta indicator */}
          <div className="mt-3 flex items-center justify-center gap-2 text-xs text-gray-500">
            <span className="flex h-1.5 w-1.5">
              <span className="animate-ping absolute inline-flex h-1.5 w-1.5 rounded-full bg-blue-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-blue-500"></span>
            </span>
            <span>NFL AI Assistant in Beta • Improving with each interaction</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFLChat;
