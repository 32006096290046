import { db } from '../../services/firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';

export const firestoreFunctions = {
  getTeams: async function({ id, season, name, code, search }) {
    try {
      const teamsRef = collection(db, 'teams');
      let q = teamsRef;
      
      // Add filters based on parameters
      if (id) {
        q = query(q, where('id', '==', id));
      }
      if (season) {
        q = query(q, where('season', '==', season));
      }
      // etc...

      const snapshot = await getDocs(q);
      const teams = snapshot.docs.map(doc => doc.data());
      
      return {
        success: true,
        data: teams
      };
    } catch (error) {
      return {
        success: false,
        error: error.message
      };
    }
  },

  getPlayerStatistics: async function({ id, team, season }) {
    try {
      const statsRef = collection(db, 'playerStats');
      let q = statsRef;
      
      if (id) {
        q = query(q, where('playerId', '==', id));
      }
      if (team) {
        q = query(q, where('teamId', '==', team));
      }
      if (season) {
        q = query(q, where('season', '==', season));
      }

      const snapshot = await getDocs(q);
      const stats = snapshot.docs.map(doc => doc.data());
      
      return {
        success: true,
        data: stats
      };
    } catch (error) {
      return {
        success: false,
        error: error.message
      };
    }
  },

  getPredictionData: async function({ gameId, sport = 'NFL', collection: collectionName }) {
    try {
      if (!gameId) {
        return {
          success: false,
          error: "Game ID is required"
        };
      }

      // Determine which collection to use
      const collectionPath = sport === 'NBA' ? 'nba_predictionData' : 'predictionData';
      // Allow override if collection name is explicitly provided
      const finalCollection = collectionName || collectionPath;

      const docRef = doc(db, finalCollection, `pred_${gameId}`);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        return {
          success: false,
          error: `No prediction data found for this ${sport} game`
        };
      }

      const data = docSnap.data();
      console.log(`${sport} Prediction data retrieved:`, data); // Debug log

      return {
        success: true,
        data: data
      };
    } catch (error) {
      console.error(`Error in get${sport}PredictionData:`, error);
      return {
        success: false,
        error: error.message
      };
    }
  }
}; 