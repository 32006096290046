import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { createCheckoutSession } from '../../services/stripe';

const SubscribeButton = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const handleSubscribe = async () => {
    setLoading(true);
    try {
      console.log('Starting checkout session creation...', user.uid);
      const result = await createCheckoutSession(user.uid);
      console.log('Checkout session result:', result);
    } catch (error) {
      console.error('Detailed subscription error:', {
        message: error.message,
        code: error.code,
        stack: error.stack
      });
      alert('Failed to start subscription process. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      onClick={handleSubscribe}
      disabled={loading}
      className={`
        w-full bg-blue-500 text-white rounded-md px-4 py-2 
        font-medium hover:bg-blue-600 transition-colors
        ${loading ? 'opacity-50 cursor-not-allowed' : ''}
      `}
    >
      {loading ? 'Loading...' : 'Subscribe - $4.99/month'}
    </button>
  );
};

export default SubscribeButton;