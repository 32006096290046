import React, { useState } from 'react';

const NBAPlayerGameItem = ({ gameStats }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-gray-800/50 rounded-lg overflow-hidden">
      {/* Header - Always visible */}
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-4 py-3 flex items-center hover:bg-gray-700/50"
      >
        <div className="flex items-center w-48">
          <img 
            src={gameStats.opponent.logo} 
            alt={gameStats.opponent.name}
            className="w-6 h-6 mr-2"
          />
          <span className="text-white">vs {gameStats.opponent.name}</span>
        </div>
        
        <div className="flex-1 flex items-center">
          <span className="text-gray-300 mr-8">
            {gameStats.score.team} - {gameStats.score.opponent}
          </span>
          <span className="text-gray-500">
            {new Date(gameStats.gameDate).toLocaleDateString()}
          </span>
        </div>

        <div className="text-blue-400 ml-4">
          {isExpanded ? '▼' : '▶'}
        </div>
      </button>

      {/* Collapsible Content */}
      {isExpanded && (
        <div className="p-4 border-t border-gray-700/50">
          <div className="space-y-3">
            {gameStats.players.map(player => (
              <NBAPlayerStatsCard 
                key={player.id}
                player={player}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const NBAPlayerStatsCard = ({ player }) => {
  return (
    <div className="bg-gray-800/50 p-3 rounded">
      <div className="flex items-center mb-2">
        <span className="text-white font-medium">{player.name}</span>
        <span className="text-gray-400 text-sm ml-2">#{player.number}</span>
      </div>
      
      <div className="grid grid-cols-4 gap-2 text-sm">
        <StatItem label="Points" value={player.points} />
        <StatItem label="Rebounds" value={player.rebounds?.total} />
        <StatItem label="Assists" value={player.assists} />
        <StatItem label="Blocks" value={player.blocks} />
      </div>
    </div>
  );
};

export default NBAPlayerGameItem; 