import { useState, useEffect } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../services/firebase';
import { useSport } from '../contexts/SportContext';

export const useGames = () => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { activeSport } = useSport();

  useEffect(() => {
    const fetchNFLGames = async () => {
      try {
        console.log('Fetching NFL games...');
        const oddsRef = collection(db, 'odds');
        const oddsSnapshot = await getDocs(query(oddsRef));
        
        const gameIds = new Set();
        oddsSnapshot.docs.forEach(doc => {
          const gameId = doc.data().gameId;
          if (gameId) gameIds.add(gameId);
        });

        const gamesRef = collection(db, 'games');
        const gamesSnapshot = await getDocs(query(gamesRef));
        
        const gamesData = gamesSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(game => gameIds.has(game.id))
          .map(game => {
            const gameOdds = oddsSnapshot.docs
              .filter(doc => doc.data().gameId === game.id)
              .map(doc => doc.data());
            
            return {
              ...game,
              odds: gameOdds
            };
          });

        setGames(gamesData);
      } catch (err) {
        console.error('Error fetching NFL games:', err);
        setError(err.message);
      }
    };

    const fetchNBAGames = async () => {
      try {
        console.log('Fetching NBA games...');
        // First get odds to filter games
        const nbaOddsRef = collection(db, 'nba_odds');
        const nbaOddsSnapshot = await getDocs(query(nbaOddsRef));
        
        // Extract game IDs from odds records
        const gameIds = new Set();
        nbaOddsSnapshot.docs.forEach(doc => {
          // Extract gameId from the document name format: odds_432350_XXXXXX
          const gameId = doc.id.split('_')[1];
          if (gameId) gameIds.add(gameId);
        });

        // Fetch games and filter by gameIds from odds
        const nbaGamesRef = collection(db, 'nba_games');
        const nbaGamesSnapshot = await getDocs(query(nbaGamesRef));
        
        const gamesData = nbaGamesSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(game => gameIds.has(game.id.toString()))
          .map(game => {
            // Get odds for this specific game
            const gameOdds = nbaOddsSnapshot.docs
              .filter(doc => doc.id.includes(`odds_${game.id}_`))
              .map(doc => doc.data());
            
            return {
              ...game,
              odds: gameOdds
            };
          });

        setGames(gamesData);
      } catch (err) {
        console.error('Error fetching NBA games:', err);
        setError(err.message);
      }
    };

    const fetchGames = async () => {
      setLoading(true);
      setError(null);
      
      if (activeSport === 'NBA') {
        await fetchNBAGames();
      } else {
        await fetchNFLGames();
      }
      
      setLoading(false);
    };

    fetchGames();
  }, [activeSport]);

  return { games, loading, error };
};