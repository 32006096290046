import React from 'react';
import { useNBAPlayerSeasonAverages } from '../../../../hooks/sports/useNBAPlayerSeasonAverages';

const NBAPlayerStats = ({ teamId }) => {
  const { playerAverages, loading, error } = useNBAPlayerSeasonAverages(teamId);

  if (loading) {
    return <div className="text-gray-400 text-center py-4">Loading player stats...</div>;
  }

  if (error) {
    return <div className="text-red-400 text-center py-4">Error: {error}</div>;
  }

  // Sort players by impact (minutes * games played)
  const sortedPlayers = [...playerAverages].sort((a, b) => {
    // Calculate "impact score" based on minutes and games played
    const impactScoreA = (a.minutes || 0) * (a.gamesPlayed || 0);
    const impactScoreB = (b.minutes || 0) * (b.gamesPlayed || 0);
    return impactScoreB - impactScoreA; // Sort descending
  });

  return (
    <div className="space-y-2 max-h-[400px] overflow-y-auto pr-2">
      {sortedPlayers.map((player) => (
        <PlayerContainer key={player.playerId} player={player} />
      ))}
    </div>
  );
};

const PlayerContainer = ({ player }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  // Calculate percentages from makes/attempts
  const calculatePercentage = (makes, attempts) => {
    if (!attempts || attempts === 0) return 0;
    return Math.round((makes / attempts) * 100);
  };

  // Calculate shooting percentages
  const fieldGoalPercentage = calculatePercentage(player.fieldGoals?.total, player.fieldGoals?.attempts);
  const threePointPercentage = calculatePercentage(player.threePoints?.total, player.threePoints?.attempts);
  const freeThrowPercentage = calculatePercentage(player.freeThrows?.total, player.freeThrows?.attempts);

  return (
    <div className="bg-gray-800/50 rounded-lg overflow-hidden">
      {/* Header - Always visible */}
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full px-4 py-3 flex items-center justify-between hover:bg-gray-700/50"
      >
        <div className="flex items-center">
          <span className="text-white font-medium">{player.playerName}</span>
          <span className="text-gray-400 text-sm ml-2">
            {player.gamesPlayed} Games Played • {Math.round(player.minutes)} MPG
          </span>
        </div>
        <div className="text-blue-400">
          {isExpanded ? '▼' : '▶'}
        </div>
      </button>

      {/* Collapsible Content */}
      {isExpanded && (
        <div className="p-4 border-t border-gray-700/50">
          <div className="grid grid-cols-2 gap-x-12 text-sm">
            <div className="space-y-1">
              <div className="text-xs text-gray-400 uppercase">Scoring</div>
              <div className="flex justify-between">
                <span className="text-gray-400">Points</span>
                <span className="text-white">{player.points?.toFixed(1)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-400">3P%</span>
                <span className="text-white">{threePointPercentage}%</span>
              </div>
            </div>
            
            <div className="space-y-1">
              <div className="text-xs text-gray-400 uppercase">Other</div>
              <div className="flex justify-between">
                <span className="text-gray-400">Rebounds</span>
                <span className="text-white">{player.rebounds?.total?.toFixed(1)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-400">Assists</span>
                <span className="text-white">{player.assists?.toFixed(1)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-400">FT%</span>
                <span className="text-white">{freeThrowPercentage}%</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NBAPlayerStats; 