import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useAuth } from '../../contexts/AuthContext';
import ChatWindow from '../ChatWindow/ChatWindow';
import GameOverview from '../GameOverview/GameOverview';
import { Link } from 'react-router-dom';
import { useSport } from '../../contexts/SportContext';
import NFLGameCardPredictions from '../GameOverview/sports/NFL/NFLGameCardPredictions';
import NBAGameCardPredictions from '../GameOverview/sports/NBA/NBAGameCardPredictions';
import { createPortal } from 'react-dom';

const GameCard = ({ game }) => {
  const { activeSport } = useSport();
  const { user } = useAuth();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isOverviewOpen, setIsOverviewOpen] = useState(false);
  const [predictions, setPredictions] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const { teams } = game;
  const gameDate = new Date(activeSport === 'NBA' ? game.date : game.date.timestamp * 1000);
  
  useEffect(() => {
    if (!user) return;

    const subscriptionRef = collection(db, 'customers', user.uid, 'subscriptions');
    const unsubscribe = onSnapshot(subscriptionRef, (snapshot) => {
      const activeSub = snapshot.docs.find(doc => doc.data().status === 'active');
      if (activeSub) {
        setSubscription(activeSub.data());
      }
    });

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    const fetchPredictions = async () => {
      try {
        const collectionName = activeSport === 'NBA' ? 'nba_betPredictions' : 'betPredictions';
        const predictionRef = doc(db, collectionName, `ai_${game.id}`);
        const predictionDoc = await getDoc(predictionRef);
        
        if (predictionDoc.exists()) {
          setPredictions(predictionDoc.data());
        }
      } catch (error) {
        console.error('Error fetching predictions:', error);
      }
    };

    fetchPredictions();
  }, [game.id, activeSport]);

  const handleSubscriptionFeatureClick = () => {
    if (!user) {
      alert('Please log in to access this feature');
      return;
    }
    if (!subscription?.status === 'active') {
      alert('Premium subscription required to access this feature');
      return;
    }
  };

  const renderPredictions = () => {
    if (activeSport === 'NBA') {
      return <NBAGameCardPredictions predictions={predictions} />;
    }
    return <NFLGameCardPredictions predictions={predictions} />;
  };

  return (
    <div className="relative bg-[#0B1120]/90 backdrop-blur-xl rounded-xl overflow-hidden isolate">
      {/* Ambient lighting effects */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-500/[0.02] via-transparent to-purple-500/[0.02]"></div>
      <div className="absolute -left-[100%] top-[50%] w-[300%] h-1/2 bg-gradient-to-br from-blue-500/[0.08] via-transparent to-transparent rotate-12 blur-3xl"></div>
      
      {/* Holographic edge effect */}
      <div className="absolute inset-0 rounded-xl border border-white/[0.05] shadow-[inset_0_1px_1px_rgba(255,255,255,0.05)]"></div>
      <div className="absolute inset-[-1px] bg-gradient-to-r from-transparent via-white/[0.05] to-transparent"></div>

      {/* Game Date Header */}
      <div className="relative px-5 py-3 border-b border-white/[0.05]">
        <div className="flex items-center space-x-2">
          <div className="w-1 h-4 bg-gradient-to-b from-blue-500 to-blue-600/50 rounded-full"></div>
          <span className="text-[11px] font-medium tracking-[0.15em] uppercase text-gray-400/90 font-['Space_Grotesk'] drop-shadow-[0_0_1px_rgba(0,0,0,0.5)]">
            {gameDate.toLocaleDateString('en-US', { 
              weekday: 'short',
              month: 'short', 
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit'
            })}
          </span>
        </div>
      </div>

      {/* Teams Section */}
      <div className="relative px-5 py-4">
        <div className="grid grid-cols-[1fr_auto_1fr] items-center gap-4">
          {/* Away Team */}
          <div className="flex items-center space-x-4 group">
            <div className="relative">
              <div className="absolute -inset-2 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-2xl blur group-hover:blur-xl transition-all duration-300 opacity-0 group-hover:opacity-100"></div>
              <div className="relative">
                <div className="w-14 h-14 rounded-2xl bg-gradient-to-br from-gray-800/80 to-gray-900 p-2.5 border border-white/[0.08] transform group-hover:scale-105 transition-all duration-300">
                  <img 
                    src={teams.away.logo} 
                    alt={teams.away.name}
                    className={`w-full h-full object-contain transform transition-transform duration-300 ${activeSport === 'NBA' ? 'rounded-full' : ''}`}
                  />
                </div>
                <div className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-8 h-[1px] bg-gradient-to-r from-transparent via-blue-500/50 to-transparent"></div>
              </div>
            </div>
            <span className="text-[15px] font-bold text-white/95 tracking-wide font-['Space_Grotesk'] drop-shadow-[0_2px_3px_rgba(0,0,0,0.5)]">
              {teams.away.name}
            </span>
          </div>

          {/* VS Section */}
          <div className="px-4 py-2 rounded-lg bg-white/[0.02] border border-white/[0.05] backdrop-blur-md w-[60px] flex justify-center">
            <span className="text-sm font-bold bg-gradient-to-br from-blue-100 via-white to-blue-100 bg-clip-text text-transparent font-['Chakra_Petch'] drop-shadow-[0_2px_2px_rgba(0,0,0,0.5)]">
              VS
            </span>
          </div>

          {/* Home Team */}
          <div className="flex items-center justify-end space-x-4 group">
            <span className="text-[15px] font-bold text-white/95 tracking-wide font-['Space_Grotesk'] drop-shadow-[0_2px_3px_rgba(0,0,0,0.5)]">
              {teams.home.name}
            </span>
            <div className="relative">
              <div className="absolute -inset-2 bg-gradient-to-r from-purple-500/20 to-blue-500/20 rounded-2xl blur group-hover:blur-xl transition-all duration-300 opacity-0 group-hover:opacity-100"></div>
              <div className="relative">
                <div className="w-14 h-14 rounded-2xl bg-gradient-to-br from-gray-800/80 to-gray-900 p-2.5 border border-white/[0.08] transform group-hover:scale-105 transition-all duration-300">
                  <img 
                    src={teams.home.logo} 
                    alt={teams.home.name}
                    className={`w-full h-full object-contain transform transition-transform duration-300 ${activeSport === 'NBA' ? 'rounded-full' : ''}`}
                  />
                </div>
                <div className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-8 h-[1px] bg-gradient-to-r from-transparent via-blue-500/50 to-transparent"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Predictions Section */}
      {renderPredictions()}

      {/* Actions Footer */}
      <div className="grid grid-cols-2 border-t border-white/[0.05]">
        <button 
          onClick={() => {
            if (!user || subscription?.status !== 'active') {
              handleSubscriptionFeatureClick();
              return;
            }
            setIsChatOpen(true);
          }}
          className={`
            relative group py-3 flex items-center justify-center space-x-2
            ${user && subscription?.status === 'active'
              ? 'text-blue-400 hover:bg-blue-500/[0.08]'
              : 'text-gray-500 hover:bg-gray-800/30'
            } transition-all duration-200 font-['Space_Grotesk']
          `}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/[0.03] to-transparent opacity-0 group-hover:opacity-100 transition-all duration-200"></div>
          <span>💬</span>
          <span className="text-xs font-semibold tracking-[0.1em] uppercase drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]">
            A.I. Expert Chat
          </span>
          {(!user || subscription?.status !== 'active') && <span>🔒</span>}
        </button>

        <button 
          onClick={() => {
            if (!user || subscription?.status !== 'active') {
              handleSubscriptionFeatureClick();
              return;
            }
            setIsOverviewOpen(true);
          }}
          className={`
            relative group py-3 flex items-center justify-center space-x-2 border-l border-white/[0.05]
            ${user && subscription?.status === 'active'
              ? 'text-gray-400 hover:bg-gray-700/30'
              : 'text-gray-500 hover:bg-gray-800/30'
            } transition-all duration-200 font-['Space_Grotesk']
          `}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-gray-500/[0.03] to-transparent opacity-0 group-hover:opacity-100 transition-all duration-200"></div>
          <span>📊</span>
          <span className="text-xs font-semibold tracking-[0.1em] uppercase drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]">
            Full Overview & Predictions
          </span>
          {(!user || subscription?.status !== 'active') && <span>🔒</span>}
        </button>
      </div>

      {/* Premium CTA */}
      {(!user || subscription?.status !== 'active') && (
        <div className="bg-gradient-to-br from-blue-500/[0.08] to-purple-500/[0.08] border-t border-blue-500/20 p-4">
          <div className="text-center space-y-3">
            <p className="text-sm font-bold text-blue-400 font-['Space_Grotesk'] tracking-wide drop-shadow-[0_1px_2px_rgba(0,0,0,0.5)]">
              <span className="mr-2">✨</span>
              Unlock Premium Features
              <span className="ml-2">✨</span>
            </p>
            <p className="text-xs text-gray-400/90 font-['Inter'] tracking-wide">
              Chat with our AI Expert and access detailed predictions
            </p>
            <Link 
              to="/account" 
              className="inline-block bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white text-sm font-bold px-6 py-2 rounded-full transition-colors duration-200 font-['Space_Grotesk'] tracking-wide drop-shadow-[0_2px_4px_rgba(0,0,0,0.3)]"
            >
              Upgrade to Premium
            </Link>
          </div>
        </div>
      )}

      {/* Move modals outside the card using portals */}
      {isChatOpen && user && subscription?.status === 'active' && createPortal(
        <ChatWindow game={game} onClose={() => setIsChatOpen(false)} />,
        document.getElementById('modal-root')
      )}
      
      {isOverviewOpen && user && subscription?.status === 'active' && createPortal(
        <GameOverview game={game} predictions={predictions} onClose={() => setIsOverviewOpen(false)} />,
        document.getElementById('modal-root')
      )}
    </div>
  );
};

export default GameCard;