import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { 
  BoltIcon, 
  ChartBarIcon, 
  ChatBubbleBottomCenterTextIcon,
  EyeIcon,
  EyeSlashIcon 
} from '@heroicons/react/24/outline';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    console.log('Starting login attempt...');
    
    try {
      await login(formData.email, formData.password);
      window.gtag?.('event', 'login_success', {
        'event_category': 'authentication'
      });
      console.log('Login successful, navigating to home...');
      navigate('/');
    } catch (error) {
      window.gtag?.('event', 'login_failure', {
        'event_category': 'authentication'
      });
      console.error('Login error:', error);
      setError('Invalid email or password');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-[#1a1f2e] to-blue-900">
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 -left-4 w-72 h-72 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob"></div>
        <div className="absolute top-0 -right-4 w-72 h-72 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-2000"></div>
        <div className="absolute -bottom-8 left-20 w-72 h-72 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-4000"></div>
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="lg:hidden mb-6 flex justify-center">
          <button 
            onClick={() => setShowLoginForm(prev => !prev)} 
            className="bg-gradient-to-r from-emerald-400 to-emerald-500 text-white 
            rounded-lg px-8 py-3 font-semibold shadow-lg hover:opacity-90 
            transition-all transform hover:scale-105 active:scale-100
            border border-emerald-400/30"
          >
            {showLoginForm ? 'Show Features' : 'Login'}
          </button>
        </div>

        <div className={`flex flex-col lg:flex-row items-center justify-center gap-8`}>
          <div className={`w-full lg:w-4/12 backdrop-blur-sm bg-[#1a1f2e]/80 p-6 rounded-2xl shadow-2xl border border-gray-800 
            ${showLoginForm ? 'block' : 'hidden lg:block'}`}>
            <h2 className="text-2xl font-bold text-white mb-6">Welcome Back</h2>
            
            {error && (
              <div className="bg-red-500/10 border border-red-500 text-red-500 rounded p-3 mb-4">
                {error}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-gray-300 mb-1">Email</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={(e) => setFormData({...formData, email: e.target.value})}
                  className="w-full bg-gray-800 rounded-lg p-3 text-white border border-gray-700 
                    focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all"
                  required
                />
              </div>

              <div>
                <label className="block text-gray-300 mb-1">Password</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    value={formData.password}
                    onChange={(e) => setFormData({...formData, password: e.target.value})}
                    className="w-full bg-gray-800 rounded-lg p-3 text-white border border-gray-700 
                      focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all pr-10"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 
                      hover:text-gray-300 focus:outline-none"
                  >
                    {showPassword ? (
                      <EyeSlashIcon className="h-5 w-5" />
                    ) : (
                      <EyeIcon className="h-5 w-5" />
                    )}
                  </button>
                </div>
              </div>

              <button
                type="submit"
                className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg p-3 
                  font-semibold hover:opacity-90 transition-all transform hover:scale-105 active:scale-100
                  shadow-lg mt-6"
              >
                Login
              </button>

              <div className="text-center text-gray-400 mt-4">
                Don't have an account?{' '}
                <Link to="/register" className="text-blue-400 hover:text-blue-300 font-semibold">
                  Sign up for free →
                </Link>
              </div>
            </form>
          </div>

          <div className={`w-full lg:w-7/12 text-white space-y-6 
            ${showLoginForm ? 'hidden lg:block' : 'block'}`}>
            <div className="text-center lg:text-left">
              <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
                Get Free AI-Powered Picks for Every Game!
              </h1>
              <p className="text-xl text-gray-300 mb-6">
                Start winning today with our top 3 most popular picks per game—first touchdown, 
                total points, and money line predictions. Perfect for beginners!
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start mb-8">
                <Link 
                  to="/register" 
                  className="inline-block bg-gradient-to-r from-blue-500 to-purple-500 text-white 
                    px-8 py-3 rounded-lg font-semibold hover:opacity-90 transition-all text-center
                    transform hover:scale-105 active:scale-100 shadow-lg"
                >
                  Start Winning Today - Free!
                </Link>
                <div className="inline-block bg-green-500/20 text-green-400 px-6 py-3 rounded-lg 
                  border border-green-500/30 animate-pulse">
                  🔥 Join 1800+ winning players!
                </div>
              </div>
            </div>

            <div className="grid sm:grid-cols-3 gap-4">
              <div className="backdrop-blur-sm bg-white/5 p-5 rounded-xl border border-gray-700 
                hover:border-blue-500/50 transition-all transform hover:scale-105">
                <BoltIcon className="w-8 h-8 text-blue-400 mb-3" />
                <h3 className="font-semibold text-lg mb-2">Free AI Picks</h3>
                <p className="text-gray-400">Get 3 top predictions for every game</p>
              </div>
              <div className="backdrop-blur-sm bg-white/5 p-5 rounded-xl border border-gray-700 
                hover:border-purple-500/50 transition-all transform hover:scale-105">
                <ChatBubbleBottomCenterTextIcon className="w-8 h-8 text-purple-400 mb-3" />
                <h3 className="font-semibold text-lg mb-2">Live AI Chat</h3>
                <p className="text-gray-400">Get instant stats and insights</p>
              </div>
              <div className="backdrop-blur-sm bg-white/5 p-5 rounded-xl border border-gray-700 
                hover:border-indigo-500/50 transition-all transform hover:scale-105">
                <ChartBarIcon className="w-8 h-8 text-indigo-400 mb-3" />
                <h3 className="font-semibold text-lg mb-2">Smart Analysis</h3>
                <p className="text-gray-400">AI-powered winning predictions</p>
              </div>
            </div>

            <div className="space-y-4 pt-4">
              <div className="backdrop-blur-sm bg-white/5 p-4 rounded-xl border border-gray-700 
                hover:border-blue-500/50 transition-all">
                <h3 className="font-semibold mb-2">🎁 Is this really free?</h3>
                <p className="text-gray-400">
                  Yes! Get our top 3 AI-powered picks for every game, completely free. 
                  No credit card required.
                </p>
              </div>
              <div className="backdrop-blur-sm bg-white/5 p-4 rounded-xl border border-gray-700 
                hover:border-blue-500/50 transition-all">
                <h3 className="font-semibold mb-2">🎯 What predictions will I get?</h3>
                <p className="text-gray-400">
                  First touchdown, total points, and money line predictions for every game—backed by AI!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;