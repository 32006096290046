import React from 'react';
import PredictionCard from '../../PredictionCard';

const NFLPredictions = ({ predictions }) => {
  return (
    <div className="space-y-4 pb-4">
      {/* Quick Predictions */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
        <PredictionCard
          label="Game Winner"
          prediction={predictions?.gameWinner}
          type="gameWinner"
        />
        <PredictionCard
          label="Total Points"
          prediction={predictions?.teamTotalPoints}
          type="totalPoints"
        />
        <PredictionCard
          label="First TD Scorer"
          prediction={predictions?.firstTouchdown}
          type="firstTouchdown"
        />
      </div>

      {/* Detailed Predictions */}
      <div className="mt-6 space-y-2">
        <h4 className="text-sm font-medium text-gray-400 mb-4">Detailed Predictions</h4>
        <div className="grid grid-cols-1 gap-2">
          <PredictionCard
            label="QB Passing Yards"
            prediction={predictions?.qbPassingYards}
            type="qbPassingYards"
          />
          <PredictionCard
            label="Top TD Scorers"
            prediction={predictions?.topTouchdownScorers}
            type="topTouchdownScorers"
          />
          <PredictionCard
            label="First Team to Score"
            prediction={predictions?.firstToScore}
            type="firstToScore"
          />
          <PredictionCard
            label="First Half Winner"
            prediction={predictions?.firstHalfWinner}
            type="firstHalfWinner"
          />
          <PredictionCard
            label="Winning Margin"
            prediction={predictions?.winningMargin}
            type="winningMargin"
          />
          <PredictionCard
            label="Home Team Points"
            prediction={predictions?.teamTotalPoints?.home}
            type="teamTotalPoints"
          />
          <PredictionCard
            label="Away Team Points"
            prediction={predictions?.teamTotalPoints?.away}
            type="teamTotalPoints"
          />
        </div>
      </div>
    </div>
  );
};

export default NFLPredictions; 