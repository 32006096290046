import React, { useState } from 'react';
import { useGameDetails } from '../../../../hooks/useGameDetails';

const NFLPlayerGameItem = ({ gameId, players }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { gameDetails, loading } = useGameDetails(gameId);
  const [selectedGroup, setSelectedGroup] = useState('all');

  const statGroups = ['all', 'Defensive', 'Rushing', 'Receiving', 'Fumbles', 'Punt_returns'];

  // Debug logs
  console.log('NFLPlayerGameItem - gameId:', gameId);
  console.log('NFLPlayerGameItem - players:', players);
  console.log('NFLPlayerGameItem - gameDetails:', gameDetails);

  if (!players || !gameDetails) return null;

  // Skip preseason games
  if (gameDetails.stage === 'Pre Season') return null;

  // Get all unique stat groups from players
  const availableGroups = new Set();
  Object.values(players).forEach(player => {
    if (player.statGroups) {
      Object.keys(player.statGroups).forEach(group => availableGroups.add(group));
    }
  });

  // Sort games by stage and week (matching NFLGameStatItem logic)
  const stageOrder = {
    'Post Season': 3,
    'Regular Season': 2
  };

  const sortedGames = Object.entries(players)
    .sort((a, b) => {
      const stageComparison = (stageOrder[gameDetails.stage] || 0);
      
      if (stageComparison === 0) {
        const weekA = parseInt(gameDetails.week?.replace('Week ', '') || '0');
        const weekB = parseInt(gameDetails.week?.replace('Week ', '') || '0');
        return weekB - weekA;
      }
      
      return stageComparison;
    });

  return (
    <div className="bg-gray-800/30 rounded-lg overflow-hidden">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-between p-3 hover:bg-gray-700/30"
      >
        <div className="flex items-center space-x-3">
          {gameDetails && (
            <>
              <div className="flex items-center space-x-2">
                <img src={gameDetails.teams.home.logo} alt={gameDetails.teams.home.name} className="w-6 h-6" />
                <span className="text-sm text-gray-400">vs</span>
                <img src={gameDetails.teams.away.logo} alt={gameDetails.teams.away.name} className="w-6 h-6" />
              </div>
              <span className="text-sm text-gray-300">{gameDetails.week}</span>
              {gameDetails.stage === 'Post Season' && (
                <span className="text-xs text-blue-400 bg-blue-400/20 px-2 py-1 rounded">
                  Playoffs
                </span>
              )}
            </>
          )}
          {loading && <span className="text-sm text-gray-400">Loading game details...</span>}
        </div>
        <span className="text-gray-400 transform transition-transform duration-200">
          {isExpanded ? '▼' : '▶'}
        </span>
      </button>

      {isExpanded && (
        <div className="p-3">
          <div className="flex space-x-2 overflow-x-auto pb-2 mb-3">
            {Array.from(availableGroups).map(group => (
              <button
                key={group}
                onClick={() => setSelectedGroup(group)}
                className={`px-3 py-1 rounded-full text-sm whitespace-nowrap ${
                  selectedGroup === group
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-800/50 text-gray-400 hover:bg-gray-700/50'
                }`}
              >
                {group.charAt(0).toUpperCase() + group.slice(1).replace('_', ' ')}
              </button>
            ))}
          </div>

          <div className="space-y-3">
            {sortedGames
              .filter(([_, player]) => 
                selectedGroup === 'all' || 
                player.statGroups?.[selectedGroup]
              )
              .map(([playerId, player]) => (
                <NFLPlayerStatsCard 
                  key={playerId}
                  player={player}
                  selectedGroup={selectedGroup}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

const NFLPlayerStatsCard = ({ player, selectedGroup }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  return (
    <div className="bg-gray-800/50 rounded p-2">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-between"
      >
        <div className="flex items-center space-x-2">
          <img src={player.player.image} alt="" className="w-8 h-8 rounded-full" />
          <span className="text-white">{player.player.name}</span>
        </div>
        <span className="text-gray-400">{isExpanded ? '▼' : '▶'}</span>
      </button>

      {isExpanded && (
        <div className="mt-2 grid gap-2">
          {Object.entries(player.statGroups)
            .filter(([group]) => selectedGroup === 'all' || group === selectedGroup)
            .map(([group, stats]) => (
              <div key={group} className="bg-gray-900/50 p-2 rounded">
                <div className="text-xs text-gray-400 mb-1">{group}</div>
                <div className="grid grid-cols-2 gap-1">
                  {stats.map((stat, index) => (
                    <div key={index} className="text-sm">
                      <span className="text-gray-400">{stat.name}: </span>
                      <span className="text-white">{stat.value || '0'}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default NFLPlayerGameItem; 