import React from 'react';
import InfoTooltip from '../common/InfoTooltip';
import { useSport } from '../../contexts/SportContext';

const PredictionCard = ({ label, prediction, type }) => {
  const { activeSport } = useSport();

  const getValue = () => {
    if (!prediction) return 'Analyzing...';
    
    switch (type) {
      case 'gameWinner':
        return prediction.winner || 'Analyzing...';
      
      case 'totalPoints':
        if (prediction.home && prediction.away) {
          const total = prediction.home.predictedPoints + prediction.away.predictedPoints;
          return `${total} points`;
        }
        return prediction.predictedPoints ? `${prediction.predictedPoints} points` : 'Analyzing...';
      
      case 'firstToScore':
        return prediction.team || 'Analyzing...';

      case 'winningMargin':
        return prediction.margin ? `${prediction.margin} points (${prediction.winner})` : 'Analyzing...';

      case 'firstHalfWinner':
        return prediction.team || 'Analyzing...';

      case 'teamTotalPoints':
        return prediction.predictedPoints ? `${prediction.predictedPoints} points` : 'Analyzing...';
      
      case 'pointSpread':
        if (prediction.spread && prediction.favoredTeam) {
          return `${prediction.favoredTeam} -${prediction.spread}`;
        }
        return 'Analyzing...';
      
      default:
        return 'Analyzing...';
    }
  };

  const getConfidence = () => {
    if (!prediction) return '--';
    
    switch (type) {
      case 'gameWinner':
      case 'firstToScore':
      case 'winningMargin':
      case 'firstHalfWinner':
        return Math.round(prediction.confidence * (prediction.confidence < 1 ? 100 : 1)) || '--';
      
      case 'teamTotalPoints':
      case 'totalPoints':
        if (prediction.confidence) {
          return Math.round(prediction.confidence * (prediction.confidence < 1 ? 100 : 1));
        }
        if (prediction.home?.confidence && prediction.away?.confidence) {
          const avgConf = (prediction.home.confidence + prediction.away.confidence) / 2;
          return Math.round(avgConf * (avgConf < 1 ? 100 : 1));
        }
        return '--';
      
      case 'pointSpread':
        return prediction.confidence || '--';
      
      default:
        return '--';
    }
  };

  const renderSpecialContent = () => {
    if (type === 'firstTouchdown' && prediction?.predictions) {
      return (
        <div className="space-y-1">
          {prediction.predictions.slice(0, 3).map((p) => (
            <div key={p.player} className="flex justify-between items-center">
              <div className="text-sm font-medium">{p.player}</div>
              <div className="text-sm text-blue-400">
                {Math.round(p.confidence * (p.confidence < 1 ? 100 : 1))}%
              </div>
            </div>
          ))}
        </div>
      );
    }

    if (type === 'topTouchdownScorers' && prediction?.predictions) {
      return (
        <div className="space-y-1">
          {prediction.predictions.map((p) => (
            <div key={p.player} className="flex justify-between items-center">
              <div className="text-sm font-medium">{p.player}</div>
              <div className="text-sm text-blue-400">
                {Math.round(p.confidence * (p.confidence < 1 ? 100 : 1))}%
              </div>
            </div>
          ))}
        </div>
      );
    }

    if (type === 'qbPassingYards' && prediction?.home && prediction?.away) {
      return (
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <div className="text-sm font-medium">{prediction.home.qb}</div>
            <div className="text-sm text-blue-400">
              {prediction.home.status ? 
                <span className="text-yellow-500">Injured - No Prediction Available</span> :
                `${prediction.home.yards} yards (${Math.round(prediction.home.confidence)}%)`
              }
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="text-sm font-medium">{prediction.away.qb}</div>
            <div className="text-sm text-blue-400">
              {prediction.away.status ? 
                <span className="text-yellow-500">Injured - No Prediction Available</span> :
                `${prediction.away.yards} yards (${Math.round(prediction.away.confidence)}%)`
              }
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const getReasoning = () => {
    if (!prediction) return null;
    
    switch (type) {
      case 'gameWinner':
      case 'firstToScore':
      case 'winningMargin':
        return prediction.reasoning || null;
      
      case 'firstHalfWinner':
        return prediction.reasoning ? 
          `${prediction.team} is predicted to lead at halftime.\n\nReasoning: ${prediction.reasoning}` : 
          null;
      
      case 'teamTotalPoints':
      case 'totalPoints':
        return prediction.reasoning || 
          (prediction.home && prediction.away ? 
            `Home: ${prediction.home.reasoning}\nAway: ${prediction.away.reasoning}` : 
            null);
      
      case 'qbPassingYards':
        return prediction.home && prediction.away ?
          `${prediction.home.qb}: ${prediction.home.reasoning}\n\n${prediction.away.qb}: ${prediction.away.reasoning}` :
          null;
      
      case 'firstTouchdown':
      case 'topTouchdownScorers':
        return prediction.predictions?.map(p => 
          `${p.player} (${p.team}): ${p.reasoning}`
        ).join('\n\n') || null;
      
      default:
        return null;
    }
  };

  const specialContent = renderSpecialContent();

  return (
    <div className="bg-gray-800/50 p-3 rounded">
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <div className="flex items-center space-x-2">
            <div className="text-xs text-gray-400">{label}</div>
            <InfoTooltip content={getReasoning()} />
          </div>
          {specialContent || (
            <div className="text-sm font-medium text-white">
              {getValue()}
            </div>
          )}
        </div>
        {!['firstTouchdown', 'qbPassingYards', 'topTouchdownScorers'].includes(type) && (
          <div className="text-sm text-blue-400 ml-4">
            {getConfidence()}%
          </div>
        )}
      </div>
    </div>
  );
};

export default PredictionCard;