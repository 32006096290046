import React, { useState } from 'react';
import { usePlayerStats } from '../../hooks/usePlayerStats';
import PlayerGameItem from './PlayerGameItem';
import PlayerSeasonAverageItem from './PlayerSeasonAverageItem';
import { calculatePlayerSeasonAverages } from '../../utils/playerStatsAverages';

const PlayerStats = ({ teamId }) => {
  const { playerStats, loading, error } = usePlayerStats(teamId);
  const [selectedGroup, setSelectedGroup] = useState('all');
  const seasonAverages = calculatePlayerSeasonAverages(playerStats);

  if (loading) {
    return <div className="text-gray-400 text-center py-4">Loading player stats...</div>;
  }

  if (error) {
    return <div className="text-red-400 text-center py-4">Error: {error}</div>;
  }

  const statGroups = ['all', 'Defensive', 'Rushing', 'Receiving', 'Fumbles', 'Punt_returns'];

  return (
    <div className="space-y-4">
      <div className="flex space-x-2 overflow-x-auto pb-2">
        {statGroups.map(group => (
          <button
            key={group}
            onClick={() => setSelectedGroup(group)}
            className={`px-3 py-1 rounded-full text-sm whitespace-nowrap ${
              selectedGroup === group
                ? 'bg-blue-500 text-white'
                : 'bg-gray-800/50 text-gray-400 hover:bg-gray-700/50'
            }`}
          >
            {group.charAt(0).toUpperCase() + group.slice(1).replace('_', ' ')}
          </button>
        ))}
      </div>

      <div className="space-y-2 max-h-[400px] overflow-y-auto pr-2">
        <PlayerSeasonAverageItem averages={seasonAverages} selectedGroup={selectedGroup} />
        {Object.entries(playerStats)
          .sort(([gameIdA], [gameIdB]) => Number(gameIdB) - Number(gameIdA))
          .map(([gameId, gameStats]) => (
            <PlayerGameItem 
              key={gameId} 
              gameId={gameId} 
              players={gameStats.players}
              selectedGroup={selectedGroup}
            />
          ))}
      </div>
    </div>
  );
};

export default PlayerStats; 