import React, { useState } from 'react';
import { useGameDetails } from '../../../../hooks/useGameDetails';
import { calculateSeasonAverages } from '../../../../utils/statsAverages';
import SeasonAverageItem from '../../SeasonAverageItem';

const NFLGameStatItem = ({ stats }) => {
  // Handle array of stats (team view)
  if (Array.isArray(stats)) {
    console.log('All incoming stats:', stats.map(s => ({
      gameId: s.gameId,
      stage: s.game?.stage,
      week: s.game?.week
    })));

    // First, strictly filter out Pre Season games
    const nonPreseasonStats = stats.filter(stat => {
      const isPreSeason = stat.game?.stage === 'Pre Season';
      console.log(`GameID: ${stat.gameId}, Stage: ${stat.game?.stage}, IsPreSeason: ${isPreSeason}`);
      return !isPreSeason;
    });

    console.log('After filtering preseason:', nonPreseasonStats.map(s => ({
      gameId: s.gameId,
      stage: s.game?.stage,
      week: s.game?.week
    })));

    // Then sort the filtered games
    const sortedStats = nonPreseasonStats.sort((a, b) => {
      const stageOrder = {
        'Post Season': 3,
        'Regular Season': 2
      };
      
      const stageComparison = (stageOrder[b.game?.stage] || 0) - (stageOrder[a.game?.stage] || 0);
      
      if (stageComparison === 0) {
        const weekA = parseInt(a.game?.week?.replace('Week ', '') || '0');
        const weekB = parseInt(b.game?.week?.replace('Week ', '') || '0');
        return weekB - weekA;
      }
      
      return stageComparison;
    });

    const seasonAverages = calculateSeasonAverages(sortedStats);
    
    return (
      <div className="space-y-2">
        {/* Season Averages at the top */}
        <SeasonAverageItem averages={seasonAverages} />

        {/* List of filtered and sorted games */}
        <div className="space-y-2">
          {sortedStats.map((gameStat) => (
            <IndividualGameStat key={gameStat.gameId} stat={gameStat} />
          ))}
        </div>
      </div>
    );
  }

  // Single game view - don't show if it's preseason
  if (stats.game?.stage === 'Pre Season') return null;
  return <IndividualGameStat stat={stats} />;
};

// Individual game component
const IndividualGameStat = ({ stat }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { gameDetails, loading } = useGameDetails(stat?.gameId);

  if (!stat || !stat.statistics) return null;

  const {
    statistics: {
      yards = {},
      points_against = {},
      passing = {},
      rushings = {},
      first_downs = {},
      posession = {},
    }
  } = stat;

  return (
    <div className="bg-gray-800/30 rounded-lg overflow-hidden">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-between p-3 hover:bg-gray-700/30"
      >
        <div className="flex items-center space-x-3">
          {gameDetails && (
            <>
              <div className="flex items-center space-x-2">
                <img 
                  src={gameDetails.teams.home.logo} 
                  alt={gameDetails.teams.home.name}
                  className="w-6 h-6"
                />
                <span className="text-sm text-gray-400">vs</span>
                <img 
                  src={gameDetails.teams.away.logo} 
                  alt={gameDetails.teams.away.name}
                  className="w-6 h-6"
                />
              </div>
              <span className="text-sm text-gray-300">
                 {gameDetails.week}
              </span>
            </>
          )}
          {loading && (
            <span className="text-sm text-gray-400">Loading game details...</span>
          )}
        </div>
        <span className="text-gray-400 transform transition-transform duration-200">
          {isExpanded ? '▼' : '▶'}
        </span>
      </button>

      {isExpanded && (
        <div className="p-3 space-y-4">
          {/* Offensive Stats */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">Offense</h4>
            <div className="grid grid-cols-2 gap-2">
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Total Yards</div>
                <div className="text-sm text-white">{yards?.total || 0}</div>
              </div>
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Points</div>
                <div className="text-sm text-white">{points_against?.total || 0}</div>
              </div>
            </div>
          </div>

          {/* Passing Stats */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">Passing</h4>
            <div className="bg-gray-800/50 p-2 rounded">
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <div className="text-xs text-gray-400">Comp/Att</div>
                  <div className="text-sm text-white">{passing?.comp_att || '0-0'}</div>
                </div>
                <div>
                  <div className="text-xs text-gray-400">Yards</div>
                  <div className="text-sm text-white">{passing?.total || 0}</div>
                </div>
              </div>
            </div>
          </div>

          {/* Rushing Stats */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">Rushing</h4>
            <div className="bg-gray-800/50 p-2 rounded">
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <div className="text-xs text-gray-400">Attempts</div>
                  <div className="text-sm text-white">{rushings?.attempts || 0}</div>
                </div>
                <div>
                  <div className="text-xs text-gray-400">Yards</div>
                  <div className="text-sm text-white">{rushings?.total || 0}</div>
                </div>
              </div>
            </div>
          </div>

          {/* Additional Stats */}
          <div>
            <h4 className="text-xs text-gray-400 uppercase mb-2">Additional Stats</h4>
            <div className="grid grid-cols-2 gap-2">
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">First Downs</div>
                <div className="text-sm text-white">{first_downs?.total || 0}</div>
              </div>
              <div className="bg-gray-800/50 p-2 rounded">
                <div className="text-xs text-gray-400">Possession</div>
                <div className="text-sm text-white">{posession?.total || '00:00'}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NFLGameStatItem; 