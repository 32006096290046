import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { 
  ShieldCheckIcon, 
  BoltIcon, 
  TrophyIcon,
  EyeIcon,
  EyeSlashIcon 
} from '@heroicons/react/24/outline';

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      window.gtag?.('event', 'registration_error', {
        'event_category': 'error',
        'event_label': 'password_mismatch'
      });
      return;
    }
    
    try {
      await register(formData.email, formData.password, formData.name);
      window.gtag?.('event', 'sign_up', {
        'event_category': 'engagement',
        'event_label': 'registration_success'
      });
      navigate('/');
    } catch (error) {
      window.gtag?.('event', 'registration_error', {
        'event_category': 'error',
        'event_label': 'registration_failure'
      });
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-[#1a1f2e] to-blue-900">
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 -left-4 w-72 h-72 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob"></div>
        <div className="absolute top-0 -right-4 w-72 h-72 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-2000"></div>
        <div className="absolute -bottom-8 left-20 w-72 h-72 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-4000"></div>
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-8">
            <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
              Start Your Free Membership Today!
            </h1>
            <p className="text-xl text-gray-300 mb-4">
              Get instant access to AI-powered sports predictions—no credit card required.
            </p>
            <div className="inline-block bg-green-500/20 text-green-400 px-6 py-2 rounded-full border border-green-500/30">
              🔥 Join 1800+ smart bettors using AI predictions!
            </div>
          </div>

          <div className="text-center mb-6">
            <Link 
              to="/login" 
              className="text-gray-400 hover:text-white transition-colors inline-flex items-center gap-1"
            >
              Already have an account? <span className="font-semibold">Log in →</span>
            </Link>
          </div>

          <div className="flex flex-col md:flex-row gap-8 items-start">
            <div className="w-full md:w-1/2 backdrop-blur-sm bg-[#1a1f2e]/80 p-6 rounded-2xl shadow-2xl border border-gray-800">
              <h2 className="text-2xl font-bold text-white mb-6">Create Your Free Account</h2>
              
              {error && (
                <div className="bg-red-500/10 border border-red-500 text-red-500 rounded p-3 mb-4">
                  {error}
                </div>
              )}

              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-gray-300 mb-1">Name</label>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    value={formData.name}
                    onChange={(e) => setFormData({...formData, name: e.target.value})}
                    className="w-full bg-gray-800 rounded-lg p-3 text-white border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all"
                    required
                  />
                </div>

                <div>
                  <label className="block text-gray-300 mb-1">Email</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                    className="w-full bg-gray-800 rounded-lg p-3 text-white border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all"
                    required
                  />
                </div>

                <div>
                  <label className="block text-gray-300 mb-1">Password</label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Choose a password"
                      value={formData.password}
                      onChange={(e) => setFormData({...formData, password: e.target.value})}
                      className="w-full bg-gray-800 rounded-lg p-3 text-white border border-gray-700 
                        focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all pr-10"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 
                        hover:text-gray-300 focus:outline-none"
                    >
                      {showPassword ? (
                        <EyeSlashIcon className="h-5 w-5" />
                      ) : (
                        <EyeIcon className="h-5 w-5" />
                      )}
                    </button>
                  </div>
                </div>

                <div>
                  <label className="block text-gray-300 mb-1">Confirm Password</label>
                  <div className="relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm your password"
                      value={formData.confirmPassword}
                      onChange={(e) => setFormData({...formData, confirmPassword: e.target.value})}
                      className="w-full bg-gray-800 rounded-lg p-3 text-white border border-gray-700 
                        focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all pr-10"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 
                        hover:text-gray-300 focus:outline-none"
                    >
                      {showConfirmPassword ? (
                        <EyeSlashIcon className="h-5 w-5" />
                      ) : (
                        <EyeIcon className="h-5 w-5" />
                      )}
                    </button>
                  </div>
                </div>

                {formData.password && formData.confirmPassword && (
                  <div className={`text-sm ${
                    formData.password === formData.confirmPassword 
                      ? 'text-green-400' 
                      : 'text-red-400'
                  }`}>
                    {formData.password === formData.confirmPassword 
                      ? '✓ Passwords match' 
                      : '✗ Passwords do not match'}
                  </div>
                )}

                <button
                  type="submit"
                  className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg p-3 
                    font-semibold hover:opacity-90 transition-all transform hover:scale-105 active:scale-100
                    shadow-lg mt-6"
                >
                  Start Winning Today - Free!
                </button>
              </form>
            </div>

            <div className="w-full md:w-1/2 text-white space-y-6">
              <div className="space-y-6">
                <div className="flex items-start space-x-4 backdrop-blur-sm bg-white/5 p-6 rounded-xl border border-gray-700">
                  <BoltIcon className="w-8 h-8 text-blue-400 flex-shrink-0" />
                  <div>
                    <h3 className="font-semibold text-lg mb-2">Weekly Free Picks</h3>
                    <p className="text-gray-400">Get AI-powered predictions for every game, delivered straight to your account</p>
                  </div>
                </div>

                <div className="flex items-start space-x-4 backdrop-blur-sm bg-white/5 p-6 rounded-xl border border-gray-700">
                  <TrophyIcon className="w-8 h-8 text-purple-400 flex-shrink-0" />
                  <div>
                    <h3 className="font-semibold text-lg mb-2">Beginner Friendly</h3>
                    <p className="text-gray-400">Simple, clear predictions that help you make smarter betting decisions</p>
                  </div>
                </div>

                <div className="flex items-start space-x-4 backdrop-blur-sm bg-white/5 p-6 rounded-xl border border-gray-700">
                  <ShieldCheckIcon className="w-8 h-8 text-emerald-400 flex-shrink-0" />
                  <div>
                    <h3 className="font-semibold text-lg mb-2">Start Instantly</h3>
                    <p className="text-gray-400">Sign up in less than 2 minutes and get immediate access to all free features</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;