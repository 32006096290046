import { useNBAPlayerStats } from './sports/useNBAPlayerStats';
import { useNFLPlayerStats } from './sports/useNFLPlayerStats';
import { useSport } from '../contexts/SportContext';

export const usePlayerStats = (teamId) => {
  const { activeSport } = useSport();
  
  const nbaStats = useNBAPlayerStats(teamId);
  const nflStats = useNFLPlayerStats(teamId);

  return activeSport === 'NBA' ? nbaStats : nflStats;
}; 