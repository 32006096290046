import React from 'react';
import InfoTooltip from '../../../common/InfoTooltip';

const NBAGameCardPredictions = ({ predictions }) => {
  return (
    <div className="flex flex-col gap-2 p-3">
      {/* Game Winner */}
      <div className="bg-gray-800/50 p-2 rounded flex justify-between items-start">
        <div>
          <div className="flex items-center space-x-2">
            <div className="text-xs text-gray-400">Projected Winner</div>
            <InfoTooltip content={predictions?.gameWinner?.reasoning} />
          </div>
          <div className="text-sm font-medium truncate max-w-[160px]">
            {predictions?.gameWinner?.winner || 'Analyzing...'}
          </div>
        </div>
        <div className="text-sm text-blue-400">
          {predictions?.gameWinner?.confidence ? 
            `${Math.round(predictions.gameWinner.confidence)}%` 
            : '--'}
        </div>
      </div>

      {/* Total Points */}
      <div className="bg-gray-800/50 p-2 rounded flex justify-between items-start">
        <div>
          <div className="flex items-center space-x-2">
            <div className="text-xs text-gray-400">Total Points</div>
            <InfoTooltip content={predictions?.totalPoints?.reasoning} />
          </div>
          <div className="text-sm font-medium">
            {predictions?.totalPoints?.total ? 
              `${predictions.totalPoints.total} points` 
              : 'Analyzing...'}
          </div>
        </div>
        <div className="text-sm text-blue-400">
          {predictions?.totalPoints?.confidence ? 
            `${Math.round(predictions.totalPoints.confidence)}%` 
            : '--'}
        </div>
      </div>
    </div>
  );
};

export default NBAGameCardPredictions;
