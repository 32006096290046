import React, { useState, useRef, useEffect } from 'react';
import OpenAI from 'openai';
import { firestoreFunctions } from './firestoreFunctions';
import { marked } from 'marked';
import InfoTooltip from '../common/InfoTooltip';

const NBAChat = ({ game, onClose, SuggestionBubbles, conversationStarters }) => {
  const { teams } = game;
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([
    { 
      text: `Hi! I can help you analyze the ${game.teams.away.name} vs ${game.teams.home.name} NBA game. What would you like to know?`, 
      isAi: true 
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const messagesEndRef = useRef(null);

  const openai = new OpenAI({
    apiKey: import.meta.env.VITE_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
  });

  // Initialize thread with NBA game data
  useEffect(() => {
    const initializeThread = async () => {
      try {
        const thread = await openai.beta.threads.create();
        setThreadId(thread.id);

        // Get NBA prediction data
        const predictionData = await firestoreFunctions.getPredictionData({
          gameId: game.id.toString(),
          sport: 'NBA'
        });

        if (!predictionData.success) {
          console.error('NBA prediction data error:', predictionData.error);
          throw new Error('Failed to fetch NBA prediction data');
        }

        console.log('NBA Game Data:', game);
        console.log('NBA Prediction Data:', predictionData.data);

        // Safely access team data
        const homeTeam = predictionData.data.teams?.home || {};
        const awayTeam = predictionData.data.teams?.away || {};

        // Send initial NBA context with structured data
        await openai.beta.threads.messages.create(
          thread.id,
          {
            role: "user",
            content: `NBA Game Context: ${awayTeam.name} vs ${homeTeam.name}
                     Game Time: ${predictionData.data.date}
                     
                     Team Statistics:
                     ${homeTeam.name}:
                     - Points Per Game: ${homeTeam.seasonStats?.apiStats?.points?.scoring?.overall?.average || 'N/A'}
                     - Rebounds: ${homeTeam.seasonStats?.apiStats?.rebounds?.total || 'N/A'} per game
                     - Assists: ${homeTeam.seasonStats?.apiStats?.assistsPerGame || 'N/A'} per game
                     - Field Goal %: ${homeTeam.seasonStats?.apiStats?.fieldGoals?.percentage || 'N/A'}%
                     - Win %: ${homeTeam.seasonStats?.apiStats?.games?.overall?.winPercentage * 100 || 'N/A'}%
                     
                     ${awayTeam.name}:
                     - Points Per Game: ${awayTeam.seasonStats?.apiStats?.points?.scoring?.overall?.average || 'N/A'}
                     - Rebounds: ${awayTeam.seasonStats?.apiStats?.rebounds?.total || 'N/A'} per game
                     - Assists: ${awayTeam.seasonStats?.apiStats?.assistsPerGame || 'N/A'} per game
                     - Field Goal %: ${awayTeam.seasonStats?.apiStats?.fieldGoals?.percentage || 'N/A'}%
                     - Win %: ${awayTeam.seasonStats?.apiStats?.games?.overall?.winPercentage * 100 || 'N/A'}%
                     
                     Key Players:
                     ${homeTeam.name} Top Scorers:
                     ${homeTeam.keyPlayers?.topScorers?.map(player => 
                       `- ${player.name}: ${player.pointsPerGame} PPG (${player.efficiency} EFF, ${player.minutesPerGame} MPG)`
                     )?.join('\n') || 'No player data available'}
                     
                     ${awayTeam.name} Top Scorers:
                     ${awayTeam.keyPlayers?.topScorers?.map(player => 
                       `- ${player.name}: ${player.pointsPerGame} PPG (${player.efficiency} EFF, ${player.minutesPerGame} MPG)`
                     )?.join('\n') || 'No player data available'}
                     
                     Top Playmakers:
                     ${homeTeam.name}:
                     ${homeTeam.keyPlayers?.topPlaymakers?.map(player => 
                       `- ${player.name}: ${player.assistsPerGame} APG (${player.minutesPerGame} MPG)`
                     )?.join('\n') || 'No playmaker data available'}
                     
                     ${awayTeam.name}:
                     ${awayTeam.keyPlayers?.topPlaymakers?.map(player => 
                       `- ${player.name}: ${player.assistsPerGame} APG (${player.minutesPerGame} MPG)`
                     )?.join('\n') || 'No playmaker data available'}
                     
                     Top Rebounders:
                     ${homeTeam.name}:
                     ${homeTeam.keyPlayers?.topRebounders?.map(player => 
                       `- ${player.name}: ${player.reboundsPerGame} RPG (${player.per36Rebounds} per 36)`
                     )?.join('\n') || 'No rebounder data available'}
                     
                     ${awayTeam.name}:
                     ${awayTeam.keyPlayers?.topRebounders?.map(player => 
                       `- ${player.name}: ${player.reboundsPerGame} RPG (${player.per36Rebounds} per 36)`
                     )?.join('\n') || 'No rebounder data available'}
                     
                     Please provide NBA-specific analysis using this data.`
          }
        );
      } catch (error) {
        console.error('Error initializing NBA thread:', error);
        setMessages(prev => [...prev, { 
          text: "I apologize, but I'm having trouble accessing the NBA game data. Please try again.", 
          isAi: true 
        }]);
      }
    };

    initializeThread();
  }, [game]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || !threadId) return;

    const userMessage = inputMessage.trim();
    setInputMessage('');
    setMessages(prev => [...prev, { text: userMessage, isAi: false }]);
    setIsLoading(true);

    try {
      // Add the user's message to the thread
      await openai.beta.threads.messages.create(
        threadId,
        {
          role: "user",
          content: userMessage
        }
      );

      // Create a run with the NBA assistant
      const run = await openai.beta.threads.runs.create(
        threadId,
        {
          assistant_id: "asst_gYOFAD8578fB7l1lKozgzNt7", // NBA-specific assistant
          instructions: "You are an NBA analysis expert. Provide concise, accurate responses about NBA games, focusing on basketball-specific insights."
        }
      );

      // Poll for the response
      let response;
      while (true) {
        const runStatus = await openai.beta.threads.runs.retrieve(
          threadId,
          run.id
        );

        if (runStatus.status === 'completed') {
          const messages = await openai.beta.threads.messages.list(
            threadId
          );
          response = messages.data[0].content[0].text.value;
          break;
        } else if (runStatus.status === 'failed') {
          throw new Error('Run failed');
        }

        await new Promise(resolve => setTimeout(resolve, 500));
      }

      setMessages(prev => [...prev, { text: response, isAi: true }]);
    } catch (error) {
      console.error('Error in NBA chat:', error);
      setMessages(prev => [...prev, { 
        text: "I apologize, but I encountered an error processing your NBA-related request.", 
        isAi: true 
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleClearChat = () => {
    setMessages([
      { text: 'How can I help analyze this NBA game?', isAi: true }
    ]);
  };

  const formatAiMessage = (message) => {
    const renderedMessage = marked(message);
    return (
      <div
        className="prose prose-invert"
        dangerouslySetInnerHTML={{ __html: renderedMessage }}
      ></div>
    );
  };

  const handleSuggestionSelect = (suggestion) => {
    setInputMessage(suggestion);
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-[#1a1f2e] rounded-lg w-full max-w-lg shadow-xl max-h-[90vh] flex flex-col">
        {/* Game Header */}
        <div className="p-4 flex flex-col items-center bg-[#141824] rounded-t-lg shrink-0 relative">
          {/* Beta badge */}
          <div className="absolute top-2 right-2 flex items-center gap-2">
            <span className="text-xs text-gray-400">NBA AI Assistant in Beta</span>
            <span className="flex h-2 w-2">
              <span className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-blue-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-500"></span>
            </span>
          </div>

          {/* Teams */}
          <div className="flex items-center justify-center gap-4 mb-2">
            <div className="flex flex-col items-center">
              <img src={teams.away.logo} alt={teams.away.name} className="w-10 h-10" />
              <div className="text-white mt-1">{teams.away.name}</div>
            </div>
            <div className="text-gray-400">vs</div>
            <div className="flex flex-col items-center">
              <img src={teams.home.logo} alt={teams.home.name} className="w-10 h-10" />
              <div className="text-white mt-1">{teams.home.name}</div>
            </div>
          </div>
        </div>

        {/* Chat Header */}
        <div className="flex items-center justify-between p-3 border-b border-gray-700 shrink-0">
          <h3 className="text-lg font-medium text-white">NBA Game Analysis</h3>
          <div className="flex gap-2">
            <button
              onClick={handleClearChat}
              className="text-gray-400 hover:text-white text-sm px-2 py-1 rounded bg-gray-800/50"
            >
              Clear Chat
            </button>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white"
            >
              ✕
            </button>
          </div>
        </div>

        {/* Chat Content */}
        <div className="flex-1 overflow-y-auto p-4">
          {messages.map((message, index) => (
            <div 
              key={index} 
              className={`mb-6 ${message.isAi ? 'text-gray-200 space-y-4' : 'text-blue-400 text-right'}`}
            >
              {message.isAi ? formatAiMessage(message.text) : message.text}
            </div>
          ))}
          {isLoading && (
            <div className="text-gray-400 text-center">
              Thinking...
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area */}
        <div className="p-4 border-t border-gray-700 shrink-0">
          <div className="mb-4">
            {messages.length === 1 && (
              <SuggestionBubbles 
                onSelect={handleSuggestionSelect} 
                sport="NBA"
              />
            )}
          </div>
          
          <form onSubmit={handleSubmit} className="flex gap-2">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Ask about this NBA game..."
              className="flex-1 bg-[#141824] rounded px-4 py-3 text-gray-300 focus:outline-none"
            />
            <button
              type="submit"
              disabled={!inputMessage.trim()}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Send
            </button>
          </form>

          {/* Beta indicator */}
          <div className="mt-3 flex items-center justify-center gap-2 text-xs text-gray-500">
            <span className="flex h-1.5 w-1.5">
              <span className="animate-ping absolute inline-flex h-1.5 w-1.5 rounded-full bg-blue-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-blue-500"></span>
            </span>
            <span>NBA AI Assistant in Beta • Improving with each interaction</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NBAChat;
