import { createContext, useContext, useState } from 'react';

const SportContext = createContext();

export function SportProvider({ children }) {
  const [activeSport, setActiveSport] = useState('NFL');
  
  return (
    <SportContext.Provider value={{ 
      activeSport, 
      setActiveSport,
      isNFL: activeSport === 'NFL',
      isNBA: activeSport === 'NBA',
      // Helper for collection names
      collections: {
        games: activeSport === 'NFL' ? 'games' : 'nba_games',
        predictions: activeSport === 'NFL' ? 'betPredictions' : 'nba_betPredictions',
        playerStats: activeSport === 'NFL' ? 'playerStatistics' : 'nba_playerStatistics',
        teamStats: activeSport === 'NFL' ? 'teamStatistics' : 'nba_teamStatistics'
      }
    }}>
      {children}
    </SportContext.Provider>
  );
}

export const useSport = () => useContext(SportContext); 