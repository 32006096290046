import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { SportProvider } from './contexts/SportContext';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Login from './pages/Login';
import Register from './pages/Register';
import Navbar from './components/layout/Navbar';
import GamesTest from './pages/GamesTest';
import Account from './pages/Account';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <SportProvider>
          <div className="min-h-screen bg-[#0B1120]">
            <Navbar />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route 
                path="/account" 
                element={
                  <ProtectedRoute>
                    <Account />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/" 
                element={
                  <ProtectedRoute>
                    <main className="container mx-auto py-6 px-4 sm:px-6 lg:px-8">
                      <div className="max-w-[1440px] mx-auto">
                        <GamesTest />
                      </div>
                    </main>
                  </ProtectedRoute>
                } 
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </SportProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;