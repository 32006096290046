import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';

export const useNBAPlayerSeasonAverages = (teamId) => {
  const [playerAverages, setPlayerAverages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAverages = async () => {
      if (!teamId) {
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching NBA player averages for teamId:', teamId);
        
        const averagesRef = collection(db, 'nba_playerSeasonAverages');
        const averagesQuery = query(
          averagesRef,
          where('teamId', '==', Number(teamId)),
          where('season', '==', '2024-2025')
        );
        
        const snapshot = await getDocs(averagesQuery);
        const averages = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        console.log('Found player averages:', averages.length);
        setPlayerAverages(averages);
      } catch (err) {
        console.error('Error fetching NBA player averages:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAverages();
  }, [teamId]);

  return { playerAverages, loading, error };
}; 