import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';

export const useNBATeamStats = (teamId) => {
  const [teamStats, setTeamStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      if (!teamId) {
        setLoading(false);
        return;
      }

      try {
        // First get all completed games for this team
        const gamesRef = collection(db, 'nba_games');
        const gamesQuery = query(gamesRef);
        const gamesSnapshot = await getDocs(gamesQuery);
        
        // Filter for completed games involving this team
        const completedGames = gamesSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(game => 
            (game.status?.short === 'FT' || game.status?.long === 'Game Finished') &&
            (game.teams.home.id === Number(teamId) || game.teams.away.id === Number(teamId))
          );

        // Now get stats for these games
        const statsRef = collection(db, 'nba_teamStatistics');
        const statsPromises = completedGames.map(async game => {
          const statsQuery = query(
            statsRef,
            where('gameId', '==', Number(game.id)),
            where('teamId', '==', Number(teamId))
          );
          const statsSnapshot = await getDocs(statsQuery);
          
          if (!statsSnapshot.empty) {
            const statsData = statsSnapshot.docs[0].data();
            return {
              id: statsSnapshot.docs[0].id,
              ...statsData,
              gameDate: game.date,
              opponent: game.teams.home.id === Number(teamId) 
                ? game.teams.away
                : game.teams.home,
              score: {
                team: game.teams.home.id === Number(teamId) 
                  ? game.scores.home.total
                  : game.scores.away.total,
                opponent: game.teams.home.id === Number(teamId)
                  ? game.scores.away.total
                  : game.scores.home.total
              }
            };
          }
          return null;
        });

        const statsResults = (await Promise.all(statsPromises)).filter(Boolean);
        setTeamStats(statsResults);
      } catch (err) {
        console.error('Error fetching NBA stats:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [teamId]);

  return { teamStats, loading, error };
}; 